import { createReducer, on } from '@ngrx/store';
import { Pagination } from 'src/app/shared/table/table.model';
import { Inbox } from '../models/inbox.model';
import * as InboxActions from './inbox.actions';

export interface InboxState {
  inbox: Inbox[];
  loadingInbox: boolean;
  inboxError: string;
  pagination: Pagination;
  unreadCount: number;
}

export const initialState: InboxState = {
  inbox: [],
  loadingInbox: true,
  inboxError: null,
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
  },
  unreadCount: 0,
};

export const inboxReducer = createReducer(
  initialState,
  on(InboxActions.loadInbox, (state) => ({
    ...state,
    loadingInbox: true,
  })),

  on(InboxActions.loadInboxSuccess, (state, action) => ({
    ...state,
    inbox: action.inbox,
    pagination: action.pagination,
    loadingInbox: false,
  })),

  on(InboxActions.loadInboxError, (state, action) => ({
    ...state,
    inboxError: action.errorMessage,
    loadingInbox: false,
  })),

  on(InboxActions.readInboxSuccess, (state, action) => {
    const cloneInbox = [...state.inbox];
    const findIndex = cloneInbox.findIndex((e) => e.id === action.id);
    if (findIndex !== -1) {
      const cloneInboxFind = { ...cloneInbox[findIndex], read: true };
      cloneInbox[findIndex] = cloneInboxFind;
    }
    const updatedInbox = [...cloneInbox];
    const updateUnread = state.unreadCount > 0 ? state.unreadCount - 1 : 0;
    return {
      ...state,
      unreadCount: updateUnread,
      inbox: updatedInbox,
    };
  }),

  on(InboxActions.loadUnreadCountSuccess, (state, action) => ({
    ...state,
    unreadCount: action.unread,
  }))
);
