import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from 'ngx-snackbar';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class TgdSnackbarService {
  constructor(private snackbarService: SnackbarService, private toastr: ToastrService, private router: Router) {}
  snackbarShow(message) {
    this.snackbarService.add({
      msg: message,
      timeout: 5000,
      action: { text: 'close', color: '#ffffff' },
    });
  }
  snackbarErrorShow(message) {
    this.snackbarService.add({
      msg: message,
      background: '#f05032',
      color: '#fff',
      timeout: 8000,
      action: { text: 'close', color: '#ffffff' },
    });
  }
  snackbarActionShow(message, actionText, onClick) {
    this.snackbarService.add({
      msg: message,
      timeout: 5000,
      action: { text: actionText, color: '#ffffff', onClick: onClick || null },
    });
  }

  showToastInfo(title, message, icon, url, error = false) {
    const html = `
      <div class="media toast-media ${error ? 'error' : ''}">
        <div class="icon pr-3 pl-1"><i class="ri ${icon}"></i></div>
        <div class="media-body">
          <div class="title mb-2">
           ${title}
          </div>
          <div class="body">
            ${message}
          </div>
        </div>
      </div>
    `;
    if (error) {
      this.toastr
        .error(html, null, {
          timeOut: 10000,
          enableHtml: true,
        })
        .onTap.pipe(take(1))
        .subscribe(() => this.toasterClickedHandler(url));
    } else {
      this.toastr
        .info(html, null, {
          timeOut: 10000,
          enableHtml: true,
        })
        .onTap.pipe(take(1))
        .subscribe(() => this.toasterClickedHandler(url));
    }
  }
  toasterClickedHandler(url) {
    this.router.navigateByUrl(url);
  }
}
