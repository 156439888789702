import { createAction, props } from '@ngrx/store';
import { UserDashboard } from '../../roles/models/user.model';
import { AclType, User } from '../auth.model';

export const loginStart = createAction(
  '[Auth] Login Start',
  props<{
    email: string;
    password: string;
  }>()
);

export const authenticateSuccess = createAction(
  '[Auth] Authenticate Success',
  props<{
    user: User;
    redirect: boolean;
  }>()
);

export const authenticateFail = createAction(
  '[Auth] Authenticate Fail',
  props<{
    errorMessage: string;
  }>()
);

export const getProfile = createAction(
  '[Auth] Get Profile',
  props<{
    userProfile: UserDashboard;
    acl: AclType;
  }>()
);

export const setProfile = createAction(
  '[Auth] Set Profile',
  props<{
    userProfile: UserDashboard;
  }>()
);

export const setUnreadChat = createAction(
  '[Auth] Set Unread Chat',
  props<{
    unreadChat: number;
  }>()
);

export const clearError = createAction('[Auth] Clear Error');

export const autoLogin = createAction('[Auth] Auto Login');

export const logout = createAction('[Auth] Logout');
