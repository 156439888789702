/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
import { Component, OnInit, Input, OnChanges, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TableHeader, TableSort, Pagination } from './table.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges {
  @Input() tableHeader: TableHeader[];
  @Input() mode = 'normal';
  @Output() pageChanges: EventEmitter<number> = new EventEmitter();
  @Input() pagination: Pagination = {
    currentPage: 1,
    itemsPerPage: 30,
    totalItems: 0,
  };
  page = 1;
  order: string;
  showPagination = true;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private cd: ChangeDetectorRef) {}
  filterActiveDropdown(item) {
    return item.active;
  }
  filterDeActiveDropdown(item) {
    return !item.active;
  }
  onSort(value) {
    const sortParam = this.activatedRoute.snapshot.queryParamMap.get('sort');
    const splitSort = sortParam ? sortParam.split('_') : [];
    let generatedValue = value;
    let generatedStatus = this.generateStatus(false);
    if (splitSort.length === 2 && value === splitSort[0]) {
      const status = splitSort[1];
      generatedValue = splitSort[0];
      if (status) {
        generatedStatus = this.generateStatus(status);
      }
    }
    let sort = null;
    if (generatedStatus) {
      sort = `${generatedValue}_${generatedStatus}`;
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { sort, page: 1 },
      queryParamsHandling: 'merge',
    });
  }
  onDropdownClicked(title, value) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { [title]: value, page: 1 },
      queryParamsHandling: 'merge',
    });
  }
  pageChanged($event) {
    if ($event.page !== this.page) {
      if (this.mode === 'normal') {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { page: $event.page },
          queryParamsHandling: 'merge',
        });
      } else if (this.mode === 'noUrl') {
        this.pageChanges.emit($event.page);
      }
    }
  }
  generateStatus(status) {
    let newStatus: boolean | string = false;
    switch (status) {
      case 'asc':
        newStatus = 'desc';
        break;
      case 'desc':
        newStatus = false;
        break;

      default:
        newStatus = 'asc';
        break;
    }
    return newStatus;
  }
  ngOnChanges() {
    this.showPagination = false;
    setTimeout(() => {
      this.showPagination = true;
      this.cd.markForCheck();
    }, 1000);
    if (this.pagination && this.pagination.totalItems) {
      this.page = { ...this.pagination }.currentPage;
    }
  }
  ngOnInit(): void {
    if (this.pagination && this.pagination.currentPage) {
      this.page = +this.pagination.currentPage;
    }
    this.activatedRoute.queryParamMap.subscribe((e) => {
      const sort = e.get('sort');
      if (sort === null) {
        this.findAndUpdate([...this.tableHeader], sort);
      } else {
        this.findAndUpdate([...this.tableHeader], sort);
      }
    });
  }

  findAndUpdate(data: TableHeader[], sortParam) {
    const splitSort = sortParam ? sortParam.split('_') : [];
    data.map((header) => {
      const cloneHeader = { ...header };
      const headerSort = cloneHeader.sort as TableSort;
      if (cloneHeader.sort) {
        if (splitSort.length && headerSort.value === splitSort[0]) {
          headerSort.status = splitSort[1];
        } else {
          headerSort.status = false;
        }
      }
      if (cloneHeader.children && cloneHeader.children.length !== 0) {
        cloneHeader.children.map((childHeader) => {
          const cloneHeaderChild = { ...childHeader };
          const headerSortChild = cloneHeaderChild.sort as TableSort;
          if (cloneHeaderChild.sort) {
            if (splitSort.length && headerSortChild.value === splitSort[0]) {
              headerSortChild.status = splitSort[1];
            } else {
              headerSortChild.status = false;
            }
          }
          return { ...childHeader, ...cloneHeaderChild };
        });
      }
      return { ...header, ...cloneHeader };
    });

    return data;
  }
}
