/* eslint-disable no-restricted-syntax */
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Observable, Subject } from 'rxjs';

import { tap } from 'rxjs/operators';
import { CategoryEntityService } from '../../services/category-entity.service';
import { Category, SelectedCategory } from '../../model/category.model';

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorySelectorComponent implements OnInit {
  @Input() mode = 'detail';
  @Output() selectedCategoryOutput: EventEmitter<SelectedCategory> = new EventEmitter();
  @Output() addNewCategory: EventEmitter<{ parentName: string; parentId: number }> = new EventEmitter();
  public onClose: Subject<SelectedCategory[]> = new Subject();
  category$: Observable<Category[]>;
  loading$: Observable<boolean>;
  selectedCategory: SelectedCategory[] = [];
  childCategory = [];
  constructor(public bsModalRef: BsModalRef, private categoryService: CategoryEntityService) {
    this.category$ = this.categoryService.entities$.pipe(
      tap((category) => {
        if (category.length && this.selectedCategory.length !== 0) {
          this.childCategory = this.extractChildren(category, this.selectedCategory);
        }
      })
    );
    this.loading$ = this.categoryService.loading$;
    this.categoryService.loaded$.subscribe((loaded) => {
      if (!loaded) {
        this.categoryService.getAll();
      }
    });
  }
  onAddNewCategory(index) {
    const selectedCategory = this.selectedCategory[index];
    let parentData: { parentName: string; parentId: number } = {
      parentId: null,
      parentName: null,
    };
    if (index !== null) {
      parentData = {
        parentId: selectedCategory.id,
        parentName: selectedCategory.name,
      };
    }
    this.addNewCategory.emit(parentData);
  }
  extractChildren(category, selectedCategory) {
    const child = [];
    const flatten = this.flatten(category);
    selectedCategory.forEach((cat: SelectedCategory) => {
      const index = flatten.findIndex((cate) => cate.id === cat.id);
      if (index !== -1) {
        if (flatten[index].children.length) {
          child.push(flatten[index].children);
        }
      }
    });
    return child;
  }
  flatten(array, result = []) {
    for (const { id, children } of array) {
      result.push({ id, children });
      if (children) this.flatten(children, result);
    }
    return result;
  }
  onChooseCategory(data: Category, indexChild, indexPos) {
    const children: Category[] = [...data.children];
    const selectedCategory = {
      id: data.id,
      name: data.name,
      active: data.active,
      parentId: data.parentId,
      indexChild,
      indexPos,
      itHasChildren: data.children.length !== 0,
    };
    const cloneCategory = [...this.childCategory];
    const categoryIndex = this.childCategory[indexChild];

    const selectedCategoryIndex = this.selectedCategory[indexChild];
    const cloneSelectedCategory = [...this.selectedCategory];

    if (children.length) {
      if (categoryIndex) {
        cloneCategory[indexChild] = children;
      } else {
        cloneCategory.push(children);
      }
    }
    if ((categoryIndex && cloneSelectedCategory.length - 1 < indexChild && selectedCategoryIndex.id !== data.id) || !children.length) {
      cloneCategory.splice(indexChild, cloneCategory.length + 1);
    } else if (indexChild === 0) {
      cloneCategory.splice(1, cloneCategory.length);
    }
    this.childCategory = cloneCategory;

    if (selectedCategoryIndex) {
      cloneSelectedCategory[indexChild] = selectedCategory;
    } else {
      cloneSelectedCategory.push(selectedCategory);
    }

    if (selectedCategoryIndex && selectedCategoryIndex.id !== data.id) {
      cloneSelectedCategory.splice(indexChild + 1, cloneSelectedCategory.length);
    }

    this.selectedCategory = cloneSelectedCategory;

    if (this.mode === 'edit') {
      this.selectedCategoryOutput.emit(selectedCategory);
    }
  }
  onContinue() {
    this.onClose.next(this.selectedCategory);
    this.bsModalRef.hide();
  }
  ngOnInit(): void {}
}
