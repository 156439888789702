/* eslint-disable @typescript-eslint/no-empty-interface */
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import * as fromAuth from '../views/auth-pages/store/auth.reducer';
import * as fromI18n from '../i18n/i18n.reducer';
import * as fromInbox from '../views/inbox/store/inbox.reducer';

export interface AppState {}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: fromAuth.authReducer,
  i18n: fromI18n.i18nReducer,
  inbox: fromInbox.inboxReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
