<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{ 'selectProduct' | translate }}</h4>
  <button type="button" class="close pull-right" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body py-0">
  <app-loader-container *ngIf="loading$ | async"></app-loader-container>
  <div class="tgd-table mt-3 tgd-table-product">
    <form [formGroup]="productFilter" class="mb-3 table-filter" autocomplete="off">
      <div class="row">
        <div class="col-lg-4 mr-auto">
          <div class="input-group">
            <input
              type="text"
              placeholder="{{ 'searchPlaceholder' | translate }}"
              class="form-control"
              [formControl]="productSearch"
              id="productSearch"
              name="productSearch"
            />
            <div class="input-group-append align-self-stretch hover-pointer">
              <div class="input-group-text"><i class="ri-search-line ri-xl"></i></div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-group hover-pointer">
            <div class="form-control align-items-center d-flex" (click)="openCategorySelector()">
              <ng-container *ngIf="selectedCategory.length === 0">
                {{ 'selectCategory' | translate }}
              </ng-container>
              <ng-container *ngIf="selectedCategory.length !== 0">
                <div
                  class="category-item d-flex align-items-center"
                  [class.mr-2]="last"
                  *ngFor="let category of selectedCategory; let last = last"
                >
                  {{ category.name }} <i class="ri-arrow-right-s-line ri-xl" *ngIf="!last"></i>
                </div>
              </ng-container>
            </div>
            <div class="input-group-append align-self-stretch" (click)="openCategorySelector()">
              <div class="input-group-text"><i class="ri-pencil-line ri-xl"></i></div>
            </div>
            <div class="clear p-3" *ngIf="selectedCategory.length !== 0" (click)="onClearCategory()">
              <i class="ri-close-line ri-xl"></i>
            </div>
          </div>
        </div>
      </div>
    </form>
    <app-table
      [tableHeader]="tableHeader"
      [pagination]="pagination"
      [mode]="'noUrl'"
      (pageChanges)="pageChanges($event)"
      class="position-relative"
    >
      <input
        type="checkbox"
        [(ngModel)]="masterSelected"
        class="checkbox-master"
        name="checkboxMaster"
        value="m1"
        (change)="checkUncheckAll()"
        [disabled]="singleMode && selectedProduct.length === 1"
        *ngIf="!singleMode"
      />
      <div class="table-wrapper">
        <div class="table-row d-flex mb-3 align-items-start py-3" *ngFor="let product of products; let index = index">
          <div class="table-cell table-cell-choose-action">
            <input
              type="checkbox"
              [(ngModel)]="product.isSelected"
              [disabled]="product.isDisabled"
              name="listName"
              value="{{ product.id }}"
              (change)="isAllSelected()"
            />
          </div>
          <div class="table-cell table-cell-name">
            <div class="media">
              <div class="product-image mr-3">
                <div class="ratio ratio-1-1">
                  <div class="inner">
                    <div
                      class="h-100"
                      [ngStyle]="
                        product.primaryImage && {
                          background: 'url(' + product.primaryImage + ') center center no-repeat',
                          backgroundSize: 'contain'
                        }
                      "
                    ></div>
                  </div>
                </div>
              </div>
              <div class="media-body">
                <div class="text-overflow2">
                  {{ product.title }}
                </div>
                <small
                  class="status"
                  [ngClass]="{
                    'text-success': product.status === enumProductStatus.published,
                    'text-secondary': product.status === enumProductStatus.draft,
                    'text-red': product.status === enumProductStatus.moderated,
                    'text-danger': product.status === enumProductStatus.inReview
                  }"
                >
                  {{ product.status | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="table-cells align-items-start flex-column">
            <div
              class="table-cells w-100"
              *ngFor="let variant of product.variants | slice: 0:(product.expanded ? product.variants.length : 3)"
            >
              <div class="table-cell">
                <ng-container *ngFor="let value of variant.options; let last = last">
                  {{ value }}<ng-container *ngIf="!last">,</ng-container>
                </ng-container>
              </div>
              <div class="table-cell">Rp {{ variant.retailPrice | mask: 'separator':'.' }}</div>
              <div class="table-cell">{{ variant.qty | mask: 'separator':'.' }}</div>
            </div>
            <div class="table-cells w-100 d-flex px-4 pt-3 pb-2" *ngIf="product.variants.length > 3">
              <div class="table-more position-relative justify-content-center d-flex w-100 hover-pointer" (click)="onExpandVariant(index)">
                <div class="table-more__content d-flex align-items-center">
                  <ng-container *ngIf="product.expanded">
                    <span> Close <i class="ri-arrow-top-s-line"></i> </span>
                    <i class="ri-arrow-up-s-line ri-2x ml-1"></i>
                  </ng-container>
                  <ng-container *ngIf="!product.expanded">
                    <span> {{ 'seeMoreVariant' | translate: { value: product.variants.length - 3 } }} </span>
                    <i class="ri-arrow-down-s-line ri-2x ml-1"></i>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-row p-5 justify-content-center text-secondary" *ngIf="products.length === 0 && !(loading$ | async)">
          {{ 'dataNotFound' | translate }}
        </div>
      </div>
    </app-table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onContinue()">{{ 'continue' | translate }}</button>
</div>
