import { createReducer, on } from '@ngrx/store';
import { UserDashboard } from '../../roles/models/user.model';
import { AclType, User } from '../auth.model';
import * as AuthActions from './auth.actions';

export interface AuthState {
  user: User;
  authError: string;
  loading: boolean;
  role: AclType;
  userProfile: UserDashboard;
  unreadChat: number;
}

export const initialState: AuthState = {
  user: null,
  authError: null,
  loading: false,
  role: {},
  userProfile: null,
  unreadChat: 0,
};

export const authReducer = createReducer(
  initialState,

  on(AuthActions.loginStart, (state) => ({
    ...state,
    authError: null,
    loading: true,
  })),

  on(AuthActions.authenticateSuccess, (state, action) => ({
    ...state,
    authError: null,
    loading: false,
    user: action.user,
  })),

  on(AuthActions.authenticateFail, (state, action) => ({
    ...state,
    user: null,
    authError: action.errorMessage,
    loading: false,
  })),

  on(AuthActions.logout, (state) => ({
    ...state,
    user: null,
  })),

  on(AuthActions.getProfile, (state, action) => ({
    ...state,
    userProfile: action.userProfile,
    role: action.acl,
  })),

  on(AuthActions.setProfile, (state, action) => ({
    ...state,
    userProfile: action.userProfile,
  })),

  on(AuthActions.setUnreadChat, (state, action) => ({
    ...state,
    unreadChat: action.unreadChat,
  })),

  on(AuthActions.clearError, (state) => ({
    ...state,
    authError: null,
  }))
);
