import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SnackbarService } from 'ngx-snackbar';
import { SettingService } from 'src/app/shared/services/setting.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';

import { environment } from '../../../../environments/environment';

import * as AuthActions from './auth.actions';
import { AclType, User } from '../auth.model';
import { TrackingService } from '../../../shared/services/tracking.service';
import { AuthService } from '../services/auth.service';

export interface AuthResponseData {
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
  tokenType: string;
}

const handleError = (errorRes: any) => {
  const errorMessage = 'Invalid credentials, please try again';
  if (!errorRes.error) {
    return of(AuthActions.authenticateFail({ errorMessage }));
  }

  // switch (errorRes.error.message) {
  //   case 'Invalid credentials':
  //   case 'User not found':
  //   case 'Unauthorized':
  //     errorMessage = 'Invalid credentials, please try again';
  //     break;
  //   default:
  // }
  return of(AuthActions.authenticateFail({ errorMessage }));
};

@Injectable()
export class AuthEffects {
  authRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authenticateSuccess),
        tap((action) => {
          if (action.redirect) {
            this.router.navigate(['/']);
            this.snackbarService.add({
              msg: 'You have successfully logged in.',
              timeout: 5000,
              action: { text: 'close', color: '#ffffff' },
            });
          }
        })
      ),
    { dispatch: false }
  );
  authGetMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authenticateSuccess),
      switchMap(() => {
        return this.settingService.getMe().pipe(
          map((e) => {
            const acl: AclType = {};
            const { roles, superAdmin } = e;

            if (!superAdmin) {
              [...roles].forEach((role) => {
                role.modules.forEach((module) => {
                  const checkModule = acl[module.code];
                  const access = module.access.filter((el) => {
                    return el;
                  });
                  if (checkModule === undefined) {
                    if (access.length !== 0) {
                      acl[module.code] = module.access;
                    }
                  } else {
                    const dataAccess = acl[module.code];
                    module.access.forEach((dataAcc) => {
                      if (dataAccess.indexOf(dataAcc) === -1 && (dataAcc as string) !== '') {
                        dataAccess.push(dataAcc);
                      }
                    });
                  }
                });
              });
            } else {
              // eslint-disable-next-line @typescript-eslint/dot-notation
              acl['superadmin'] = ['*'];
            }
            delete e.roles;
            const data = {
              userProfile: e,
              acl,
            };
            setTimeout(() => {
              this.settingService.loader = false;
            }, 1000);
            return AuthActions.getProfile(data);
          })
        );
      })
    )
  );
  authLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          localStorage.removeItem('userData');
          // localStorage.removeItem('deviceId');

          if (this.messagingService.taptalkModule) {
            this.messagingService.taptalkModule.taptalk.logoutAndClearAllTapTalkData({
              onSuccess: () => {
                // do action here
                // localStorage.removeItem('tapTalk.DeviceID');
                sessionStorage.removeItem('activeRoomModel');
                sessionStorage.removeItem('previewDataMessage');
                sessionStorage.removeItem('slugStore');
                (window as any).alreadySendWelcome = false;
                (window as any).RoomListener = null;
                localStorage.removeItem('rml');
                localStorage.removeItem('crm');
                localStorage.removeItem('ctc');
                localStorage.removeItem('dft');
                this.messagingService.taptalkShowroom = false;
                // eslint-disable-next-line no-restricted-globals
                location.reload();
              },
            });
          }
        })
      ),
    { dispatch: false }
  );
  autoLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autoLogin),
      map(() => {
        const userData: {
          email: string;
          id: string;
          accessToken: string;
          refreshToken: string;
          tokenExpirationDate: string;
        } = JSON.parse(localStorage.getItem('userData'));

        if (!userData) {
          return { type: 'STRG USER NOTFOUND' };
        }

        const loadedUser: User = this.authService.createUserData(userData);
        if (loadedUser.accessToken) {
          return AuthActions.authenticateSuccess({
            user: loadedUser,
            redirect: false,
          });
        }
        return { type: 'CANNOT CREATE USER' };
      })
    )
  );
  authLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginStart),
      switchMap((action) => {
        const data = {
          identifier: action.email,
          password: action.password,
          // grantType: 'password',
          grantType: 'admin',
        };
        return this.http.post<AuthResponseData>(`${environment.apiUrlDef}/accounts/login`, data).pipe(
          map((resData) => {
            const data: AuthResponseData = resData;
            const user = this.authService.createUserData(data, action.email, '1');
            localStorage.setItem('userData', JSON.stringify(user));
            this.messagingService.requestPermission();
            setTimeout(() => {
              this.trackingService.tracking('login', 'email', 'success', `{email : ${action.email}}`).subscribe();
            }, 1000);
            return AuthActions.authenticateSuccess({ user, redirect: true });
          }),
          catchError((errorRes) => {
            this.trackingService.tracking('login', 'email', 'success', `{email : ${action.email}}`, JSON.stringify(errorRes));
            return handleError(errorRes);
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private trackingService: TrackingService,
    private settingService: SettingService,
    private messagingService: MessagingService
  ) {}
}
