import * as moment from 'moment';
import { Inbox } from './models/inbox.model';

export function mapListInbox(inbox) {
  const cloneInbox: Inbox = { ...inbox };
  const date = `${moment(cloneInbox.createdAt).calendar(null, {
    sameElse: 'DD/MM/YYYY [at] h:mm A',
  })}`;
  cloneInbox.date = date;
  const inboxData = cloneInbox;
  if (cloneInbox.topicType === 'OrderList') {
    cloneInbox.url = '/order';
  } else if (cloneInbox.topic === 'Sales report generated') {
    cloneInbox.url = '/report';
  } else {
    cloneInbox.url = null;
  }
  return inboxData;
}
