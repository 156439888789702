import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-container',
  templateUrl: './loader-container.component.html',
  styleUrls: ['./loader-container.component.scss'],
})
export class LoaderContainerComponent implements OnInit {
  ngOnInit(): void {}
}
