import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { AuthState } from './auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const getUser = createSelector(selectAuthState, (auth) => auth.user);

export const getProfile = createSelector(selectAuthState, (auth) => auth.userProfile);

export const getAuthError = createSelector(selectAuthState, (auth) => auth.authError);

export const isAuthLoading = createSelector(selectAuthState, (auth) => auth.loading);

export const isLoggedIn = createSelector(selectAuthState, (auth) => !!auth.user);

export const isProfileLoaded = createSelector(selectAuthState, (auth) => !!auth.userProfile);

export const getTaptalkTicket = createSelector(selectAuthState, (auth) => (auth.user ? auth.user.taptalkTicket : null));

export const getRole = createSelector(selectAuthState, (auth) => auth.role);

export const getUnreadChat = createSelector(selectAuthState, (auth) => auth.unreadChat);

export const checkStorePlatform = createSelector(selectAuthState, (auth) => {
  const { store } = auth.userProfile;
  return store.id === +environment.goodsStoreId;
});

export const checkFulfillmentStore = createSelector(selectAuthState, (auth) => {
  const { store } = auth.userProfile;
  console.log('store: ', store);
  return store.fulfillment;
});

export const getStore = createSelector(selectAuthState, (auth) => auth.userProfile.store);

export const getStoreSlug = createSelector(selectAuthState, (auth) => auth.userProfile.store.slug);

export const isLoggedOut = createSelector(isLoggedIn, (loggedIn) => !loggedIn);

export const checkRole = (role: string[]) =>
  createSelector(selectAuthState, (auth) => {
    const checkSuperAdmin = auth.role.superadmin;
    const rolesInput = Array.isArray(role) ? role : [role];
    const e = auth.role;
    console.log('e: ', e, role);
    if (rolesInput.length !== 0 && rolesInput[0] === 'superadmin') {
      return checkSuperAdmin !== undefined && checkSuperAdmin[0] === '*';
    }

    if (checkSuperAdmin !== undefined && checkSuperAdmin[0] === '*') {
      return true;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rolesInput.length; i++) {
      const roles = rolesInput[i].split('|');
      const checkRole = e[roles[0]];
      console.log('checkRole: ', checkRole, roles[1]);
      if (checkRole === undefined) {
        return false;
      }
      const checkAccess = checkRole.indexOf(roles[1] as any);
      if (checkAccess === -1) {
        return false;
      }
      return true;
    }
    return true;
  });
