<app-loader></app-loader>
<router-outlet></router-outlet>
<ngx-snackbar [position]="'bottom-center'"></ngx-snackbar>
<ng-template #templateModalUnauthorized>
  <div class="modal-body text-center">
    <p>Access Unauthorized.</p>
    <button type="button" class="btn btn-primary" (click)="onCloseUnauthorizedModal()">
      {{ 'Ok' | translate }}
    </button>
  </div>
</ng-template>
<app-taptalk *ngIf="isProfileLoaded$ | async"></app-taptalk>
