import { createAction, props } from '@ngrx/store';

export const setLanguage = createAction(
  '[i18n] SET LANGUAGE',
  props<{
    language: string;
  }>()
);

export const autoLanguage = createAction('[i18n] Auto Language');
