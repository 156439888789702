import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { SettingService } from 'src/app/shared/services/setting.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: [],
  animations: [trigger('inOutAnimation', [transition(':leave', [style({ opacity: 1 }), animate('0.5s ease-in', style({ opacity: 0 }))])])],
})
export class LoaderComponent implements OnInit {
  constructor(public settingService: SettingService) {}
  ngOnInit() {}
}
