import { createAction, props } from '@ngrx/store';
import { Pagination } from 'src/app/shared/table/table.model';
import { Inbox } from '../models/inbox.model';

export const loadInbox = createAction(
  '[Inbox] Load Inbox',
  props<{
    params: any;
  }>()
);

export const loadInboxSuccess = createAction(
  '[Inbox] Load Inbox Success',
  props<{
    inbox: Inbox[];
    pagination: Pagination;
  }>()
);

export const loadInboxError = createAction(
  '[Inbox] Load Inbox Success',
  props<{
    errorMessage: string;
  }>()
);

export const readInbox = createAction(
  '[Inbox] Read Inbox',
  props<{
    id: number;
  }>()
);

export const readInboxSuccess = createAction(
  '[Inbox] Read Inbox Sucess',
  props<{
    id: number;
  }>()
);

export const loadUnreadCount = createAction('[Inbox] Load Unread Count');

export const loadUnreadCountSuccess = createAction(
  '[Inbox] Load Unread Count Success',
  props<{
    unread: number;
  }>()
);
