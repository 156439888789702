import { registerElement } from '@angular-react/core';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import FloatChat from 'taptalk-chat-react-component';
import { TaptalkReactComponent } from './taptalk-react.component';

@NgModule({
  declarations: [TaptalkReactComponent],
  exports: [TaptalkReactComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class TaptalkReactModule {
  constructor() {
    registerElement('FloatChat', () => FloatChat);
  }
}
