<div class="lib-taptalk-react position-fixed">
  <lib-taptalk-react
    *ngIf="messagingService.taptalkModule && messagingService.taptalkModule.taptalk !== undefined && showTaptalk"
    [taptalk]="messagingService.taptalkModule"
    [showRoom]="messagingService.taptalkShowroom"
    [siteURL]="customerUrl"
    [setProduct]="onSetProduct"
    [showChatRoomDetail]="showChatRoomDetail"
  ></lib-taptalk-react>
</div>
