/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable } from '@angular/core';

import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Response } from './response.model';
import { environment } from '../../environments/environment';

export interface RequestOptions {
  headers?: HttpHeaders;
  observe?: any;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ResponseService {
  private apiUrl = environment.apiUrl;
  private apiUrlDef = `${environment.apiUrlDef}/v1`;
  private apiaryUrl = `${environment.apiaryUrl}`;
  apiUnauthorizedSubject: Subject<boolean> = new Subject();
  constructor(private http: HttpClient) {}
  get<T>(url: string, apiUrlDef: boolean = false, params?: RequestOptions, apiary = false): Observable<Response<T>> {
    const api = apiary ? this.apiaryUrl : !apiUrlDef ? this.apiUrl : this.apiUrlDef;
    return this.http.get<Response<T>>(api + url, params).pipe(catchError((e) => this.errorHandler(e)));
  }
  post<T>(url: string, body?: object, apiUrlDef: boolean = false, params?: RequestOptions, apiary = false): Observable<Response<T>> {
    const api = apiary ? this.apiaryUrl : !apiUrlDef ? this.apiUrl : this.apiUrlDef;
    return this.http.post<Response<T>>(api + url, body, params).pipe(catchError((e) => this.errorHandler(e)));
  }
  put<T>(url: string, body?: object, apiUrlDef: boolean = false, params?: RequestOptions, apiary = false): Observable<Response<T>> {
    const api = apiary ? this.apiaryUrl : !apiUrlDef ? this.apiUrl : this.apiUrlDef;
    return this.http.put<Response<T>>(api + url, body, params).pipe(catchError((e) => this.errorHandler(e)));
  }
  patch<T>(url: string, body?: object, apiUrlDef: boolean = false, params?: RequestOptions, apiary = false): Observable<Response<T>> {
    const api = apiary ? this.apiaryUrl : !apiUrlDef ? this.apiUrl : this.apiUrlDef;
    return this.http.patch<Response<T>>(api + url, body, params).pipe(catchError((e) => this.errorHandler(e)));
  }
  delete<T>(url: string, apiUrlDef: boolean = false, options?: RequestOptions, apiary = false): Observable<Response<T>> {
    const api = apiary ? this.apiaryUrl : !apiUrlDef ? this.apiUrl : this.apiUrlDef;
    return this.http.delete<Response<T>>(api + url, options).pipe(catchError((e) => this.errorHandler(e)));
  }

  uploadGcs<T>(url: string, file: any, mime: string): Observable<Response<T>> {
    const headers = new HttpHeaders().set('Content-Type', mime);
    const options: RequestOptions = { headers, observe: 'events', reportProgress: true };
    return this.http.put<Response<T>>(url, file, options);
  }
  uploadFile<T>(url: string, file: any, apiary = false): Observable<Response<T>> {
    const api = apiary ? this.apiaryUrl : this.apiUrl;
    const options: RequestOptions = { observe: 'events', reportProgress: true };
    return this.http.post<Response<T>>(api + url, file, options);
  }
  getFileName<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }
  errorHandler(error) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      console.error(`Error: ${error.error.message}`);
    } else {
      // server-side error
      console.error(`Error Code: ${error.status}\n Message: ${error.message}`);
    }
    if (error.error && error.error.status === 403) {
      this.apiUnauthorizedSubject.next(true);
    }
    return throwError(error);
  }
}
