/* eslint-disable func-names */
import { DefaultPersistenceResultHandler, EntityAction } from '@ngrx/data';
import { Action } from '@ngrx/store';

export class AdditionalPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);
    return function (data: any) {
      const action = actionHandler.call(this, data);
      if (action && data && data.metadata) {
        if (data.metadata.pagination) {
          (action as any).payload.pagination = data.metadata.pagination;
        } else if (data.metadata.totalItems !== undefined) {
          (action as any).payload.pagination = data.metadata;
        }
      }
      (action as any).payload.data = data.data;
      return action;
    };
  }
}
