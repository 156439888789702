/* eslint-disable prettier/prettier */
export const en = {
  "sidebar": {
    "dashboard": "DASHBOARD",
    "orders": "ORDERS",
    "myOrders": "My Orders",
    "returnCancelation": "Return / Cancelation",
    "product": "PRODUCT",
    "myProduct": "My Product",
    "createProduct": "Create Product",
    "promotion": "PROMOTION",
    "myVoucher": "My Voucher",
    "createVoucher": "Create Voucher",
    "voucherReferralList" : "Voucher Referral",
    "createVoucherReferral" : "Create Voucher Referral",
    "store": "STORE",
    "news": "NEWS",
    "newsList": "News List",
    "createNews": "Create News",
    "newsCategoryList": "News Category",
    "createNewsCategory": "Create News Category",
    "review": "Review",
    "storeProfile": "STORE PROFILE",
    "categories": "Categories",
    "setting": "SETTINGS",
    "shipping": "Shipping",
    "address": "Address",
    "payments": "Payments",
    "banner": "BANNER",
    "reports": "REPORTS",
    "manageCategory": "CATEGORY",
    "wishlist" : "Wishlist",
    "customer" : "CUSTOMER",
    "roles" : 'ROLE',
    "roleList" : 'List Role',
    "createRole" : 'Create Role',
    "userList" : 'List User',
    "createUser" : 'Create User',
    "archetype" : 'ARCHETYPE',
    "archetypeList" : 'List Archetype',
    "createArchetype": 'Create Archetype',
    "customerList" : 'List Customer',
    "myFlashSale" : "Limited Offer",
    "createFlashSale" : "Create Limited Offer",
    "storeLocatorList": 'Store Location',
    "createStoreLocator": 'Create Store Location',
    "brand": "BRAND",
    "brandList": "Brand List",
    "createBrand": "Create Brand",
    "groupCategoryList": 'GROUP CATEGORY',
    "createGroupCategory": 'Create Group Category',
    "deals": "Deals",
    "createDeals" : "Create Deals",
    "productDiscussion" : "PRODUCT DISCUSSION",
    "discussions" : "Discussions",
    "notifications" : "NOTIFICATIONS",
    "createNewNotifications" : "Create New Notification",
    "notificationList" : "Notification List",
    "productReview": "PRODUCT REVIEW",
    "merchantStore" : "MERCHANT STORE",
    "createMerchantStore" : "Create Merchant Store",
    "merchantStoreList": "Merchant Store List",
    "emailNotification": "Order Notification",
    "voucherBankPromo": "Bank Promo",
    "chat": "Chat",
    "referral" : "Referral Program",
    "promoDiscount": "Store Discount",
    "membership": "MEMBERSHIP",
    "setupMembership": "Setup Membership",
    "paymentStatusMembership": "Payment Status",
    "productModeration": "Product Moderation",
    "tieredDiscount": "Tiered Discount",
  },
  "signOut" : "Sign out",
  "product" : "Product",
  "addNewProduct" : "Add New Product",
  "productName" : "Product Name",
  "variant" : "Variant",
  "price" : "Price",
  "stock" : "Stock",
  "action" : "Action",
  "searchPlaceholder" : "Search product . . .",
  "selectCategory" : "Select category",
  "seeMoreVariant" : "See {{value}} more variant",
  "edit" : "Edit",
  "add" : "Add",
  "basicInformation" : "Basic Information",
  "productNamePlaceholder" : "Enter your product name",
  "productDescriptionPlaceholder" : "Enter your product Description",
  "productBrandPlaceholder" : "Select your product brand",
  "isRequired" : "is required",
  "isInvalid" : "is invalid",
  "productDescription" : "Product Description",
  "productCategory" : "Product Category",
  "category" : "Category",
  "productBrand" : "Product Brand",
  "productCondition" : "Product Condition",
  "used" : "Used",
  "new" : "New",
  "salesInformation" : "Sales Information",
  "productPrice" : "Product Price",
  "productPricePlaceholder" : "Enter your product price",
  "productStock" : "Product stock",
  "productStockPlaceholder": "Enter your product stock",
  "productWeight" : "Product Weight",
  "weight" : "Weight",
  "productWeightPlaceholder": "Enter your product weight",
  "productSKU" : "Product SKU",
  "productSKUPlaceholder": "Enter your product SKU",
  'retailPrice' : 'Retail Price',
  'productRetailPrice' : 'Product Retail Price',
  'productRetailPricePlaceholder' : 'Enter your product retail price',
  "activeVariant" : "Active variant",
  "Name" : "Name",
  "variantNamePlaceholder" : "Enter your variant name",
  "thisField" : "This field",
  "options" : "Options",
  "option" : "Option",
  "addOption" : "Add option",
  "addVariant" : "Add variant",
  "applyToAll" : "Apply to all",
  "variantList" : "Variant List",
  "mainPhoto" : "Main Photo",
  "photo" : "Photo",
  "continue" : "Continue",
  "pending" : "Pending",
  "notPaid": "Awaiting Payment",
  "all" : "Semua",
  "allStatus" : "All Status",
  "needToBeShipped" : "Need To Be Shipped",
  "preparingOrder" : "Preparing Order",
  "preparing_order" : "Preparing Order",
  "shipping" : "Shipping",
  "delivering" : "Delivering",
  "shipped" : "Delivered",
  "completed" : "Completed",
  "orders" : "Orders",
  "Order" : "Order",
  "Payment": "Payment",
  "customer" : "Customer",
  "orderNo" : "Order No",
  "allShipping" : "All Shipping",
  "callShipping" : "Call Shipping",
  "open": "Open",
  "close" : "Close",
  "arrangeShipment" : "Arrange Shipment",
  "confirm" : "Confirm",
  "address" : "Address",
  "date" : "Date",
  "time" : "Time",
  "shippingInformation" : "Shipping Information",
  "notPaidDescription" : "Order will be cancelled if not paid by {{value}}. Please wait for payment confirmation before shipping the items.",
  "totalOrder": "Total Order",
  "shippingTotal": "Shipping",
  "adminFee" : "Admin Fee",
  "totalSelling" : "Total Selling",
  "shippingDiscount" : "Shipping Discount",
  "discount" : "Discount",
  "totalCustomerPayment" : "Total Customer Payment",
  "buyerPayment" : "Customer Payment",
  "paymentInformation" : "Payment Information",
  "seeDetail" : "See Detail",
  "paymentReceived" : "Payment Received",
  "payment_verified" : "Payment Received",
  "orderCreated" : "Order Created",
  "pendingForPayment" : "Awating Payment",
  "cancel" : "Cancel",
  "cancelOrder" : "Cancel Order",
  "cancelCustomer" : "Cancel Customer",
  "cancelAdmin" : "Cancel Admin",
  "cancelShipping" : "Cancel Shipping",
  "cancel_shipping" : "Cancel Shipping",
  "cancelCustomerDescription" : "Order has been cancel by Customer.",
  "cancelAdminDescription" : "Order has been cancel by Admin.",
  "cancelShippingDescription" : "Order has been cancel by Delivery Service.",
  "expired" : "Expired",
  "rejected" : "Rejected",
  "orderCreatedDescription" : "Order checkout success.",
  "cancelDescription" : "Order is cancelled.",
  "expiredDescription" : "The order has been cancelled as Customer failed to make payment on time.",
  "rejectedDescription" : "Payment order is rejected.",
  "paymentReceivedDescription" : "Payment confirmed, customer is waiting order to ship.",
  "whatYouCanDoNext" : "WHAT YOU CAN DO NEXT",
  "processOrder" : "Process Order",
  "processOrderConfirmMessage" : "Are you sure want to process this order ?",
  "cancelOrderConfirmMessage" : "Are you sure want to cancel this order ?",
  "acceptComplainOrderConfirmMessage" : "Are you sure want to accept complain ?",
  "rejectComplainOrderConfirmMessage" : "Are you sure want to reject complain ?",
  "returnOrderConfirmMessage" : "Are you sure want to change order to return ?",
  "yes" : "Yes",
  "no" : "No",
  "requestAirwayBillAnteraja" : "Request Airway Bill Anteraja",
  "preparingOrderDescription" : "Customer is waiting for you to ship.",
  "deliveringDescription" : "Awaiting customer to confirm receipt",
  "delivered" : "Delivered",
  "deliveredDescription" : "Courier confirm delivery is completed.",
  "complain" : "Complain",
  "complainDescription" : "You can contact Customer, accept the complain request or reject the complain request",
  "reject" : "Reject",
  "accept" : "Accept",
  "return" : "Return",
  "anterAjaRequestPickup" : "I Will Arrange Pickup",
  "anterAjaRequestPickupDesc": "Anteraja will collect parcel from your pickup address",
  'requestPickup' : 'I will arrange pickup',
  'requestPickupDesc': 'Courier will collect parcel from your pickup address',
  'sendPackage' : 'Im going to deliver it to counter by myself',
  'sendPackageDesc' : 'You can send your package at nearby {{shipping}} branch in your city',
  "notes" : "Notes",
  "dataNotFound": "Data Not Found",
  'cancel_admin' : 'Cancel Admin',
  'awaiting_pickup' : 'Awaiting Pickup',
  'awaiting_pickupDescription': 'Waiting courir to pickup order.',
  'quantity': 'Quantity',
  'dateRangeBannerPlaceholder' : 'Input Start Date - End Date',
  'dateRangeOrderPlaceholder' : 'Input Start Date - End Date',
  'searchBannerTitlePlaceholder' : 'Search banner title . . .',
  'image' : 'Image',
  'title' : 'Title',
  'url': 'Url',
  'status' : 'Status',
  'orderBanner' : 'Order',
  'banner': 'Banner',
  'addNewBanner' : 'Add New Banner',
  'addBanner': 'Add Banner',
  'titleBannerPlaceholder' : 'Enter your banner title',
  'urlBannerPlaceholder' : 'Enter your banner url',
  'orderBannerPlaceholder': 'Enter your banner order',
  'orderDatePlaceholder': 'Enter your banner date',
  'uploadImage' : 'upload Image',
  'save' : 'Save',
  'draft' : 'Draft',
  'published' : 'Published',
  'savePublish' : 'Save & Publish',
  'confirmAnterajaRequestPickupMessage': 'Are you sure want to Request Pickup ?',
  'bestSizeBanner' : 'Recommended ratio 16:9',
  'trackQty' : 'Unlimited',
  'changePassword' : 'Change Password',
  'currentPassword' : 'Current Password',
  'newPassword' : 'New Password',
  'confirmPassword' : 'Confirm New Password',
  'confirmNewPasswordPlaceholder' : 'Enter Confirm Password',
  'currentPasswordPlaceholder' : 'Enter Current Password',
  'newPasswordPlaceholder' : 'Enter New Password',
  'passwordNotSame' : 'Invalid password confirmation',
  'orderTime' : 'Order Time',
  'myReport' : 'My Report',
  'myReportSubtitle' : 'Generate, Download & view your reports',
  "reportType" : "Report Type",
  "requestTime" : "Request Time",
  "requestAccount" : "Request Account",
  "requestName" : "Report name",
  "reportDateRangePlaceholder" : "Input Date Range",
  "basicInformationVoucher" : "Basic Information",
  "voucherTitle" : "Voucher Name",
  "voucherTitlePlaceholder" : "Enter Voucher Name",
  "voucherCode" : "Voucher Code",
  "voucherCodePlaceholder" : "Enter Voucher Code",
  "voucherStartDate" : "Voucher Start Date",
  "voucherStartDatePlaceholder": "Select Date",
  "voucherEndDate": "Voucher End Date",
  "voucherEndDatePlaceholder": "Select Date",
  "rewardSettings": "Reward Settings",
  "voucherMinOrder": "Minimum Order",
  "voucherMinOrderPlaceholder": "Enter Minimum Order",
  "voucherDiscountType": "Discount Type",
  "voucherDiscountValuePlaceholder": "Enter Discount Value",
  "voucherLimitType": "Voucher Limit Type",
  "voucherLimitValue": "Voucher Limit Value",
  "voucherLimitValuePlaceholder": "Enter Voucher Limit Value",
  "bankLimitType" : "Promo Limit Type",
  "bankLimitValue" : "Promo Limit Type",
  "bankLimitValuePlaceholder": "Enter Bank Limit Value",
  "voucherMaxDiscount": "Max Discount",
  "voucherMaxDiscountPlaceholder": "Enter Max Discount",
  "applicableVoucher": "Applicable Voucher",
  "bankApplicableTo": "Applicable Promo",
  "voucherApplicableTo": "Voucher Applicable To",
  "applicableCombination": "Can be combine with",
  "mediaSettings": "Media Settings",
  'bestSizeVoucherImage' : 'Recommended ratio image 3:1(600px x 200px)',
  "voucherClaimable": "Shown in Voucher List",
  "addProduct": "Add Product",
  "selectProduct": "Select Product",
  "choosenProduct": "Choosen Product",
  "applicableProducts": "Applicable Products",
  "applicableBrand": "Applicable Brands",
  "addApplicableBrand": "Add Brand",
  "addApplicableMerchant": "Add Merchant",
  "selectBrand" : "Select Brand",
  "selectMerchant" : "Select Merchant",
  "applicableMerchant": "applicable Merchant",
  "customerSettings": "Customer Setting",
  "applicableCustomer" : "Customer",
  "addCustomer": "Add Customer Account",
  "selectCustomer": "Select Customer",
  "choosenCustomer" : "Choosen Customer",
  "choosenMerchant": "Choosen Merchant",
  "choosenBrand": "Choosen Brand",
  "storeName": "Merchant Name",
  "back": "Back",
  "manageCategory": "Manage Category",
  "editCategory": "Edit Category",
  "categoryName": "Category Name",
  "parentCategory": "Parent Category",
  "categoryParentIdPlaceholder": "Type to search category",
  "addChildrenCategory": "Add Children Category",
  "delete": "Delete",
  "addChildrenCategoryInside": "Add Children Category Inside",
  "confirmDeleteCategoryMessage": "Are you sure want to delete this category ?",
  "confirmEditCategoryMessage": "Are you sure want to edit this category ?",
  "addNew": "Add New",
  "submit": "Submit",
  "voucherName": "Name Voucher",
  "bankPromoName": "Name Promo",
  "voucherType": "Type Voucher",
  "bankPromoType": "Type Promo",
  "voucherQuantity": "Quantity",
  "usageLimit": "Limit Usage",
  "claimed": "Claimed",
  "usage": "Used",
  "period": "Voucher Period",
  "bankPromoPeriod": "Promo Period",
  "addNewVoucher": "Add New Voucher",
  "voucher": "Voucher",
  "addNewVoucherReferral": "Add New Voucher Referral",
  "voucherReferral": "Voucher Referral",
  "toDoList": "To Do List",
  "businessInsights": "Business Insights",
  "visitors": "Visitors",
  "pageViews": "Page Views",
  "conversionRate": "Conversion Rate",
  "yesterday": "Yesterday",
  "massUpload" : "Mass Upload",
  "downloadTemplate" : "Download Template ",
  "uploadFile" : "Upload File",
  "productSoldOut": "Sold Out Product",
  "sales": "Sales",
  'messageConfirmDeleteProduct' : 'Are you sure want to delete this product ?',
  'messageConfirmDeleteVariantProduct' : 'Are you sure want to delete variant of this product ?',
  "roleDetail" : "Role Detail",
  "rolesName": "Role Name",
  "rolesNamePlaceholder" : "Enter Role Name",
  "moduleAccess" : "Module Access",
  "userLists" : "User Lists",
  "addUser": "Add User",
  "userName": "User Name",
  "userEmail": "User Email",
  "selectUser": "Select User",
  "searchUserPlaceholder": "Search User . . .",
  "module": "Modul",
  "access": "Access",
  "users": "Users",
  "addNewRole" : "Add New Role",
  "addModule" : "Add Module",
  "confirmEditRolesMessage" : "Are you sure want to edit this role ?",
  "confirmDeleteRolesMessage" : "Are you sure want to delete this role ?",
  "confirmDeleteBannerMessage" : "Are you sure want to delete this banner ?",
  "numberOfEnthusiasts" : "Enthusiasts Customer",
  "enthusiast": "Enthusiast Customers",
  "gender": "Gender",
  "addNewUser": "Add New User",
  "editUser": "Edit User",
  "createNewUser" : "Create New User",
  "fullName" : "Full Name",
  "fullNamePlaceholder" : "Enter Full Name",
  "email" : "Email",
  "emailPlaceholder" : "Enter Email",
  "password" : "Password",
  "passwordPlaceholder" : "Enter Password",
  "confirmPasswordUser" : "Confirm Password",
  "confirmPasswordUserPlaceholder" : "Enter Confirm Password",
  "userDetail" : "User Detail",
  "userRole" : "User Role",
  "archetype" : "Archetype",
  "searchArchetypePlaceholder" : "Search Title . . .",
  "archetypeCover" : "Archetype Cover",
  "addNewArchetype" : "Add New Archetype",
  "archetypeTitle": "Archetype Title",
  "archetypeTitlePlaceholder": "Enter Archetype Title",
  "addArchetype" : "Add Archetype",
  "editArchetype" : "Edit Archetype",
  "archetypeDescription" : "Archetype Description",
  "archetypeDescriptionPlaceholder" : "Enter Archetype Description",
  "archetypeTags" : "Archetype Tags",
  "productTag" : "Product Tags",
  "archetypeImages" : "Archetype Images",
  "archetypeStatus": "Archetype Status",
  "archetypeCoverBestSize": 'Recommended Size 236x354 Pixel',
  "typeInput" : "Please type for search tag",
  "typeInputBrand": "Please type for search brand",
  "createBrand" : "Create Brand",
  "birthday" : "Birthday",
  "MALE" : "Male",
  "FEMALE": "Female",
  "searchCustomerPlaceholder": "Search Customer . . .",
  "customerProfile": "Customer Profile Information",
  "customerAccount": "Customer Account Information",
  "customerAddress": "Customer Address",
  "addressType": "Address Type",
  "recipentName": "Recipent Name",
  "addressDetail": "Address Detail",
  "phoneNumber": "Phone Number",
  "customerBank": "Customer Bank",
  "bankName": "Bank Name",
  "accountNumber": "Account Number",
  "accountName": "Account Name",
  "paymentManagement" : "Payment Management",
  "confirmPaymentStatusInactive" : "Are you sure want to change this payment channel status to Inactive ?",
  "confirmPaymentStatusActive" : "Are you sure want to change this payment channel status to Active ?",
  "shippingManagement" : "Shipping Management",
  "confirmShippingStatusInactive" : "Are you sure want to change this shipping channel status to Inactive ?",
  "confirmShippingStatusActive": "Are you sure want to change this shipping channel status to Active ?",
  "warningShippingStatus": "Shipping service cannot be empty!",
  "birthDate" : "Birthdate",
  "flashSale" : "Limited Offer",
  "createFlashSale" : "Create Limited Offer",
  "flashSaleName" : "Flash Sale Name",
  "flashSaleNamePlaceholder" : "Enter Flash Sale Name",
  "flashSaleStartDate" : "Flash Sale Start Date",
  "flashSaleStartDatePlaceholder": "Select Date",
  "flashSaleEndDate" : "Flash Sale End Date",
  "flashSaleEndDatePlaceholder": "Select Date",
  "productManagement" : "Product Management",
  'bestSizeFlashSaleImage' : 'Recommended image ratio 16:9',
  "tnc" : "Terms and Conditions",
  "tncPlaceholder" : "Insert Terms and Condition",
  "shortDescription" : "Short Description",
  "startDate" : "Start Date",
  "endDate" : "End Date",
  "descripiton" : "Description",
  "descriptionPlaceholder": "Enter Description",
  "voucherQuantityPerUser": "Quantity Per User",
  "voucherQuantityPerUserPlaceholder" : "Enter Quantity Per User",
  "nonSaleItem" : "Sale Item",
  "searchStoreLocatorPlaceholder" : "Search Address or Name Store",
  "storeLocator" : "Store Location",
  "addStoreLocator" : "Add Store Location",
  "storeLocatorPhoto" : "Store Photo",
  "storeLocatorName" : "Store Name",
  "storeLocatorAddress" : "Store Address",
  "createStoreLocation" : "Create Store Location",
  "storeLocatorNamePlaceholder" : "Enter Store Name",
  "storeLocatorAddressPlaceholder" : "Enter Store Address",
  "searchAddressStoreLocator" : "Type For Search Location",
  "storeLocatorPhoneNumber" : "Store Phone Number",
  "storeLocatorPhoneNumberPlaceholder" : "Enter Store Phone Number",
  "storeLocatorPhotoBestSize" : 'Recommended ratio photo 4:3(512px x 384px)',
  "locationStore" : "Location Store",
  "flashSaleDescripiton" : "Description",
  "flashSaleDescripitonPlaceholder" : "Enter Description",
  "flashSalePosition" : "Campaign Position",
  "flashSalePositionPlaceholder" : "Choose Position",
  "flashSaleStatusPlaceholder" : "Choose Status",
  "flashSalePeriod" : "Period",
  "top" : "Top",
  "middle" : "Middle",
  "bottom": "Bottom",
  "brandSearchPlaceholder" : "Search Brand . . .",
  "brand" : "Brand",
  "brandCover" : "Brand Image",
  "brandCode" : "Brand Code",
  "brandCodePlaceholder" : "Enter Brand Code",
  "brandName" : "Brand Name",
  "brandNamePlaceholder" : "Enter Brand Name",
  "brandFeatured" : "Featured",
  "brandNotFeatured" : "Not Featured",
  "brandShortDescription" : "Brand Short Description",
  "brandShortDescriptionPlaceholder" : "Enter Brand Short Description",
  "brandDescription" : "Brand Description",
  "brandDescriptionPlaceholder" : "Enter Brand Description",
  "messageConfirmDeleteBrand" : "Are you sure want to delete this Brand ?",
  "bestSizeBrandCover": "Recommended ratio image 1:1(400px x 400px)",
  "addBrand" : "Add New Brand",
  "deals" : "Deals",
  "createDeals" : "Add New Deals",
  "dealNamePlaceholder" : "Enter Name",
  "dealsPosition" : "Deals Position",
  "dealsPositionPlaceholder" : "Choose Deals Position",
  "dealShortDescriptionPlaceholder" : "Enter Short Description",
  "dealsDescription" : "Enter Description",
  "dealsDescriptionPlaceholder" : "Enter Description",
  "dealsStartDatePlaceholder" : "Choose Start Date",
  "dealsEndDatePlaceholder" : "Choose End Date",
  "dealStatusPlaceholder": "Choose Status",
  'bestSizeDealsImage' : 'Recommended image ratio 3:1',
  "productGroupSearchPlaceholder" : "Search Group Category Name . . .",
  "productGroup" : "Group Category",
  "addProductGroup" : "Add Group Category",
  "productGroupCover" : "Group Category Cover",
  "productGroupName" : "Group Category Name",
  "productGroupNamePlaceholder" : "Enter Group Category Name",
  "productGroupShortDescription" : "Short Description",
  "productGroupDescription" : "Description",
  "productGroupDescriptionPlaceholder" : "Enter Description",
  "productGroupOnNavigation" : "Put on Navigation",
  "productGroupStatus": "Status",
  "bestSizeProductGroupCover": "Recommended size 1276px x 316px",
  "messageConfirmDeleteProductGroup": "Are you sure want to delete this Group Category ?",
  "productGroupOrder": "Group Category Ordering",
  "productGroupOrderPlaceholder": "Enter Group Category Ordering",
  "searchNewsPlaceholder" : "Search News . . .",
  "news" : "News",
  "addNews" : "Add News",
  "newsCover" : "News Image",
  "newsTitle" : "News Title",
  "newsTitlePlaceholder" : "Enter News Title",
  "newsCategory" : "Category",
  "newsCategoryPlaceholder" : "Choose Category",
  "newsShortDescription" : "Short Description",
  "newsShortDescriptionPlaceholder" : "Enter Short Description",
  "newsTag" : "Tags",
  "editNews": "Edit News",
  "newsContent" : "News Content",
  "newsContentPlaceholder" : "Enter News Content",
  "newsSlug" : "News Slug",
  "newsSlugPlaceholder" : "Enter News Slug",
  "newsStatus" : "News Status",
  "newsCoverBestSize" : "Recommended image ratio 16:9",
  "discussion" : "Discussion",
  "reportedDiscussion" : "Reported",
  "suspendedDiscussion" : "Suspended",
  "discussionUser" : "User",
  "discussionProduct" : "Product",
  "discussionMessage" : "Discussion",
  "discussionReply" : "Latest Reply",
  "discussionDate" : "Date",
  "productDiscussionReply" : "Reply",
  "blockDiscussion" : "Block",
  "unblockDiscussion" : "Unblock",
  "replyDiscussion" : "Reply",
  "replyDiscussionPlaceholder" :  "Enter Your Reply",
  "discussionReported" : "Reported",
  "discussionBlocker" : "Blocked",
  "viewAllDiscussion" : "View All Discussion",
  "discussionBlockConfirmationMessage" : "Are you sure want to block this discussion ?",
  "discussionUnblockConfirmationMessage" : "Are you sure want to unblock this discussion ?",
  "notifications" : "NOTIFICATIONS",
  "createNewNotifications" : "Create New Notification",
  "notificationList" : "Notification List",
  "notificationMessage" : "Notification Message",
  "notificationNamePlaceholder" : "Enter Title",
  "notificationMessagePlaceholder" : "Enter Notification Message",
  "notificationPublishDate" : "Publish Date",
  "notificationRecipient" : "Recipient",
  "notificationRecipientPlaceholder" : "Select Recipient",
  "notificationTitle" : "Campaign Title",
  "notificationStatus" : "Status",
  "notificationStatusPlaceholder" : "Enter Status",
  "blockingReasonPlaceholder" : "Masukkan alasan anda untuk memblokir",
  "blockingReason" : "Reason",
  "storeUser": "Store",
  "storeUserPlaceholder" : "Choose Store",
  "editProfile" : "Edit Profile",
  "changePhoto" : "Change Photo",
  "editEmail" : "Email",
  "editName" : "Name",
  "editStore" : "Store",
  "addCategoryNews" : "Add Category News",
  "editCategoryNews" : "Edit Category News",
  "categoryStatus" : "Category Status",
  "categoryNewsName" : "Category Name",
  "categoryNewsNamePlaceholder" : "Enter Category Name",
  "addNewsCategory" : "Add News Category",
  "newsTags" : "Tags",
  "unread": "Unread",
  "seeMore" : "See More",
  "markAsRead" : "Mark as read",
  "bestSizeNotificationImage": "Recommended ratio 4:3",
  "numberOfReview" : "Number of Review",
  "productRating" : "Product Rating",
  "productReviews" : "Product Reviews",
  "emptyNotification": "Notification Empty",
  "createMerchant" : "Create Merchant Store",
  "merchantStoreUrl" : "Merchant Store Url",
  "merchantStoreName" : "Merchant Store Name",
  "merchantStoreNamePlaceholder" : "Enter Merchant Store Name",
  "merchantUserFullname" : "Admin Name",
  "merchantUserFullnamePlaceholder" : "Enter Admin Name",
  "merchantUserEmail" : "Admin Email",
  "merchantUserEmailPlaceholder" : "Enter Admin Email",
  "merchantUserPassword" : "Admin Password",
  "merchantUserPasswordPlaceholder" : "Enter Admin Password",
  "merchantUserConfirmPassword" : "Merchant Admin Confirm Password",
  "merchantUserConfirmPasswordPlaceholder" : "Enter Admin Confirm Password",
  "store": "Store",
  "merchant" : "Merchant Store",
  "merchantSearchPlaceholder" : "Search Merchant . . .",
  "merchantName" : "Name",
  "merchantCity" : "City",
  "merchantJoinDate" : "Join Date",
  "merchantTotalProduct" : "Total Product",
  "merchantStatus": "Status",
  "merchantDetail" : "Merchant Detail",
  "merchantLogo" : "Merchant Logo",
  "merchantShortDiscription" : "Merchant Tagline",
  "merchantDiscription" : "Merchant Description",
  "merchantImageCover" : "Merchant Image Cover",
  "merchantOperationalHours" : "Merchant Operational Hours",
  "merchantProduct" : "Merchant Product",
  "confirmMerchantChangeStatusMessage": "Are you sure want to change this merchant status ?",
  "addMerchant": "Add Merchant Store",
  "addAddress" : "Add Address",
  "addressStoreName" : "Label",
  "addressStoreDetail" : "Address",
  "addressStorePhoneNumber" : "Phone Number",
  "createAddress" : "Add Address",
  "addressNamePlaceholder" : "Enter Label Address Example(Home, Office, Warehouse)",
  "addressDetailPlaceholder" : "Enter Detail Address",
  "locationAddress" : "Location Address",
  "addressStorePhoneNumberPlaceholder" : "Enter Phone Number",
  "addressDefault" : "Primary Address",
  "monday" : "Monday",
  "tuesday" : "Tuesday",
  "wednesday" : "Wednesday",
  "thursday" : "Thursday",
  "friday" : "Friday",
  "saturday" : "Saturday",
  "sunday" : "Sunday",
  "storeProfileTitle" : "Profile Title",
  "profileTitlePlaceholder" : "Entre Profile Title",
  "profileTitleDescription" : "Store Description",
  "profileTitleDescriptionPlaceholder" : "Enter Store Description",
  "profileLogo" : "Logo",
  "bestSizeStoreLogo" : "",
  "bestSizeStoreImageCover": "Recommended Ratio 16:9",
  "profileName" : "Store Name",
  "profileNamePlaceholder" : "Enter Store Name",
  "profileShortDescription" : "Store Tagline",
  "profileShortDescriptionPlaceholder" : "Enter Store Short Description",
  "storeImageCover" : "Store Image Cover",
  "storeOperationalHours": "Store Operational Hours",
  "storeProfile": "Store Profile",
  "productGroupTotalProduct": "Total Product",
  "targetLink": "Target Link",
  "productDetail" : "Product Detail",
  "groupCategory" : "Group Category",
  "otherUrl": "Other Url",
  "selectProductGroup": "Select Group Category",
  "addressPostalCode": "Province/City/District",
  "typeInputPostalCode": "Please type for search Province/City/District",
  "editAddress": "Edit Address",
  "moderatedProduct" : "Moderating Product",
  "moderatedReason" : "Reason",
  "moderatedReasonPlaceholder" : "Enter Reason",
  "availableForBrightspot" : "Available for Brightspot Only",
  "voucherPlatform" : "Voucher",
  "storeUrl" : "Store Url",
  "addressPostalCodeNumber" : "Postal Code",
  "isMinLength": "Minimum {{value}} characters",
  "confirmDeleteEmailMessage" : "Are you sure want to delete this email ?",
  "addEmail" : "Add Email",
  "emailInputPlaceholder": "Enter Email",
  "searchEmailPlaceholder" : "Search Email . . .",
  "isTaken" : "is already taken",
  "inputAirwayBill" : "Input Resi",
  "awbInput": "Resi Number",
  "awbInputPlaceholder": "Enter Resi Number",
  "checkAwb": "Check Resi",
  "here": "here",
  "cancel_by_system": "Cancel System",
  "cancel_by_systemDescription": "Order canceled by system, exceeded processing time.",
  "completedDescription": "Order has been received by customer.",
  "pleaseSelectCategory": "Please Select Category",
  "errorFieldButton": "Please complete all the required fields above",
  "errorFieldButtonNoVariant": "Please complete at least one variant",
  "pleaseChooseDate": "Please Select Date Pickup",
  "chooseTimeAirwaybill": "Please Select Time Pickup",
  "changeAwb": "Edit Resi",
  "nonSaleItemVoucher": "Turning this on will make voucher applicable to sale items",
  "typeInputStoreList": "Please type for search Store",
  "userType": "User Type",
  "historyModeration" : "History Moderation",
  "inReviewProduct" : "In Review Product",
  "moderated": "Moderated",
  "yourProductModereted" : "Your Product is now moderated",
  "yourProductinReview" : "This Product is In Review",
  "reason" : "Reason",
  "see" : "See",
  "in_review": "In Review",
  "welcomeMessage" : "Setting Welcome Message",
  "welcomeMessagePlaceholder": "Enter Welcome Message",
  "bankPromo": "Bank Promo",
  "addNewBankPromo" : "Add New Bank Promo",
  "bankPromoTitle" : "Bank Promo Name",
  "bankPromoTitlePlaceholder" : "Enter Bank Promo Name",
  "bankPromoStartDate" : "Bank Promo Start Date",
  "bankPromoStartDatePlaceholder" : "Enter Bank Promo Start Date",
  "bankPromoEndDate" : "Bank Promo End Date",
  "bankPromoEndDatePlaceholder" : "Enter Bank Promo End Date",
  "voucherTnc": "Term & Conditions",
  "voucherTncPlaceholder": "Enter Term & Conditions",
  "voucherPromoBankCanCombineWithVoucher" : "Promo can combine with voucher",
  "bankSettings" : "Bank Settings",
  "voucherPromoBankPayment" : "Payment Method",
  "typeInputVoucherPromoBankPayment" : "Select Payment Method",
  "voucherPromoBankBin" : "BIN Number",
  "voucherPromoBankBinPlaceholder" : "Enter Bin Number",
  "voucherPromoBankInstallment" : "Installment Payment",
  "voucherPromoBankInstallmentPlaceholder" : "Select Installment Payment",
  "applicablePromo": "Applicable Promo",
  "userCustomerService": "Customer Service",
  "voucherShortDescription" : "Short Description",
  "voucherShortDescriptionPlaceholder" : "Enter Short Description",
  "voucherAlreadyExist" : "Has Already Been Used",
  "programName" : "Program Name",
  "programNamePlaceholder" : "Enter Program Name",
  "programShortDescription" : "Short Discription Program",
  "programShortDescriptionPlacholder" : "Enter Short Discription Program",
  "programStartDate" : "Program Start Date",
  "programEndDate" : "Program End Date",
  "programParticipant" : "Program Participant",
  "programConversionTriggerPlaceholder" : "Select Conversion Trigger",
  "programConversionTrigger" : "Conversion Trigger",
  "next" : "Next",
  "prev" : "Prev",
  "programDetail" : "Program Detail",
  "referralProgram": "Referral Program",
  "openedProgram" : "Opened",
  "openedConverted" : "Converted",
  "addNewReferral" : "Add New Referral Program",
  "referralActiveProgramBlock" : "Other referral program is running, you can't run multiple referral program at same time.",
  "errorSyncHaistar": "SKU Not Found",
  "massUpdateDownloadTime" : "Date",
  "massUpdateDownloadName" : "File Name",
  "massUpdate" : "Mass Update",
  "generateTemplate" : "Generate Template",
  "dndExcelHere" : "Choose or drop file excel here",
  "browseFile": "Browse for file",
  "massUpdateDownloadSubtitle": "Please select to download templates below to update your products through Microsoft Excel.",
  "massUpdateUploadSubtitle" : "Please upload the completed files to finish the mass update process.",
  "massUpdateDownloadSubtitle30Days": "The Goods Dept will only keep your files in the last 30 days.",
  "massUpdateDownloadTips1" : "The Goods Dept mass update function would only support:",
  "massUpdateDownloadTips2" : "Microsoft Excel 2007 & higher version Windows PC.",
  "massUpdateDownloadTips3" : "Less then 5Mb per file, TSV file.",
  "massUpdateUploadTips1" : "Max size: 5MB only in TSV.",
  "massUpdateUploadTips2" : "You can edit product/sku information and get the result file for express option.",
  "massUpdateUploadTips3": "Please do not edit your product until the tasks have been completed.",
  'downloaded': 'downloaded',
  "massUpdateDownloadProcessed": "Processed",
  "tryAgain" : "Coba lagi",
  "done" : "Done",
  "generalVoucher" : "Voucher General",
  "voucherStore" : "Voucher Store",
  "addNewVoucherStore" : "Add Voucher Store",
  "addNewVoucherPlatform" : "Add Voucher",
  "paymentCancel": "Canceled",
  "voucherAvaliablePlatform":"Platform",
  "bulkPrintShipping": "Print Receipts",
  "createLabel": "Print",
  "createLabelThermal": "Print Thermal",
  "choosenOrder": "Choosen Order",
  "printDocument" : "Print Document",
  "ANTERAJA_REGULAR" : "ANTERAJA",
  "ANTERAJA_SAMEDAY" : "ANTERAJA",
  "messageConfirmProductChangeStatusTo": "Are you sure want to change product status to",
  "outOfStock": "Out Of Stock",
  "erorImage" : "Error Occur, the photo you input exceeds the available photo slot.",
  "deniedChangeStatus": "Cannot change status, product data is not completed.",
  "promoDiscountOngoing" : "On Going",
  "promoDiscountNext" : "Next",
  "promoDiscountEnded" : "Ended",
  "namePromoDiscount" : "Promotion Name",
  "promoDiscountProduct" : "Product",
  "promoDiscountPeriod" : "Period",
  "promoDiscount": "Store Discount",
  "ongoingPromoDiscount" : "Ongoing",
  "nextPromoDiscount" : "Next",
  "expiredPromoDiscount" : "Ended",
  "addPromoDiscount" : "Add Store Discount",
  "massChangePromoDiscount" : "Mass Change",
  "priceSale": "Price Sale",
  "stockPromotion" : "Promotion Stock",
  "maxPurchase" : "Limit Purchase",
  "activeInactive" : "Active / Inactive",
  "createPromoDiscount" : "Create Store Discount",
  "basicInformationPromoDiscount" : "Basic Information",
  "discountName" : "Discount Name",
  "discountNamePlaceholder" : "Enter Discount Name",
  "discountStartDate" : "Discount Start Date",
  "discountStartDatPlaceholder" : "Enter Discount Start Date",
  "discountEndDate" : "Discount End Date",
  "discountEndDatePlaceholder" : "Enter Discount End Date",
  "productPromoDiscount" : "Product in Store Discount",
  "productPromoDiscountDesc" : "Add product in store discount and set discount price",
  "totalProduct" : "Total Product",
  "stockDiscountPromo" : "Stock Promotion",
  "noUpdate" : "No Update",
  "unlimited" : "Unlimited",
  "limited" : "Limited",
  "changeAll" : "Change All",
  "invalidPrice" : "Invalid Price",
  "min99" : "Min. value 99",
  "min1" : "Min. value 1",
  "maxStockPromo" : "Stock must be less than avaliable stock right now.",
  "warningPricePromoDiscount" : "Promo price less than 40% from initial price",
  "variantIsOnDiscount" : "This product is currently in promotion",
  "inactivePromoDiscount": "Inactive",
  "promoDiscountInactive": "Inactive",
  'messageConfirmPromoDiscountEnd' : 'Are you sure want to end this Store Discount ?',
  'endPromoDiscount' : 'End',
  "membershipName" : "Membership Name",
  "membershipNamePlaceholder" : "Enter Membership Name",
  "membershipDescription" : "Membership Description",
  "membershipDescriptionPlaceholder" : "Enter Membership Description",
  "membershipImage" : "Membership Image",
  "membershipSkuPlaceholder" : "Enter Membership SKU",
  "setupMembership" : "Setup Membership",
  "setupMembershipDesc" : "Setup your membership for your customer",
  "statusMembership": "Status",
  "enter": "Enter",
  "paymentStatus" : "Payment Status",
  "paymentStatusDesc": "Check membership payment status",
  'pinpointAddress': 'Pin Address',
  'searchPinpointAddress': 'Find Address . . .',
  'confirmIntantBiteshipRequestPickupMessage': 'Are you sure want to Request Pickup right now?',
  'productLength': 'Product Length',
  'productLengthPlaceholder': 'Enter your length product',
  'productWidth': 'Product Width',
  'productWidthPlaceholder': 'Enter yout length product',
  'productHeight': 'Product Height',
  'productHeightPlaceholder': 'Enter your height product',
  'SELF_PICKUP': '',
  'processRequestPickup': 'Ready to Pickup',
  'processReadyToPickupConfirmMessage' : "Is the order ready to picked up ?",
  'pickupCode': "Pickup Code",
  'awaiting_pickupSelfPickup' : 'Waiting For Pickup',
  'awaiting_pickupDescriptionSelfPickup': 'Waiting customer to pickup order.',
  'externalUrl': 'External Url',
  'addTieredDiscount': 'Add Tiered Discount',
  'titleTieredDiscount': 'Title',
  'titleTieredDiscountPlaceholder': 'Enter tiered discount title',
  'codeTieredDiscount': 'Code',
  'codeTieredDiscountPlaceholder': 'Enter tiered discount code',
  'shortDescriptionTieredDiscount': 'Short description',
  'shortDescriptionTieredDiscountPlaceholder': 'Enter tiered discount short description',
  'startDateTieredDiscount': 'Start Date',
  'startDateTieredDiscountPlaceholder': 'Enter tiered discount start date',
  'endDateTieredDiscount': 'End Date',
  'endDateTieredDiscountPlaceholder': 'Enter tiered discount end date',
  'limitTypeTieredDiscount': 'Limit Type',
  'limitValueTieredDiscount': 'Total discount',
  'limitValueTieredDiscountPlaceholder': 'Enter total tiered discount',
  'quantityPerUserTieredDiscount': 'Quantity Per User',
  'quantityPerUserTieredDiscountPlaceholder': 'Enter quantity per user',
  'termAndConditionTieredDiscount': 'Term & Condition',
  'termAndConditionTieredDiscountPlaceholder': 'Enter Term & Condition',
  'tierCounter': 'Tiered Discount Level',
  'tierDiscountValuePlaceholder': 'Enter tiered discount value',
  'tieredDiscountConfig': 'Tiered Discount Config',
  'tieredDiscountApplicableTo': 'Applicable To',
  'tieredDiscountCanCombineWithBankPromo': 'Can combine with Bank Promo',
  'labelNameTieredDiscount': 'Label Name',
  'labelNameTieredDiscountPlaceholder': 'Enter tiered discount label name',
  'tieredDiscountSearchPlaceholder': 'Search Tiered Discount . . .',
  'tieredDiscount': 'Tiered Discount',
  'tieredDiscountName': 'Tiered Discount Name',
  'tieredDiscountlevel': 'Tiered Discount Level',
  'tieredDiscountType': 'Type',
  'tieredDiscountPeriod': 'Period',
  'seeMoreLevel': 'See {{value}} more level',
  'validationErrorApplicableTo': 'Sorry these products are included on another promo, please remove to continue',
  'selectAll': 'Select All',
  'promoDiscountSearchPlaceholder': 'Search Store Discount . . .',
  "captionRequestPickupDatetime":'Choose pickup time between 09.00 AM - 02.00 PM',
  "process":"Process",
  "integrateWithGinee":"Integrate with Ginee",
  "flashSaleSettings":"Flash Sale Settings",
  "flashSaleTargetType":"Flash Sale Target Type",
  "flashSaleTargetTypePlaceholder":"Choose Target Type",
  "applicablePromoDiscount":"Applicable Store Discount",
  "selectPromoDiscount": "Select Store Discount",
  "choosenPromoDiscount": "Choosen Store Discount",
  'productPriceOnFlashSale' : 'Flash Sale Price',
  'productStockOnFlashSale' : 'Flash Sale Stock',
  "stockSale" : "Stock Sale",
  "warningPriceFlashSale" : "Flash Sale price less than 40% from initial price",
  "invalidStock": "Invalid Stock",
}
