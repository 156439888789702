import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseService } from '../../../shared/response.service';
import { Response } from '../../../shared/response.model';
import { Inbox } from '../models/inbox.model';
import { mapListInbox } from '../inbox.helper';
@Injectable({
  providedIn: 'root',
})
export class InboxService {
  constructor(private http: ResponseService) {}
  getInbox(params): Observable<Response<Inbox[]>> {
    return this.http.get('/inbox', false, { params }).pipe(
      map((response: Response<Inbox[]>) => {
        const cloneResp = { ...response };
        (cloneResp as any).data = (cloneResp as any).data.map((inbox) => mapListInbox(inbox));
        return { ...response, ...cloneResp };
      })
    );
  }
  getInboxCounter() {
    return this.http.get('/inbox/list/counter');
  }
  readInbox(id) {
    return this.http.get(`/inbox/${id}`);
  }
}
