<app-loader-container *ngIf="loading$ | async"></app-loader-container>
<div class="modal-header" *ngIf="mode === 'detail'">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{ 'selectCategory' | translate }}</h4>
  <button type="button" class="close pull-right" (click)="onContinue()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body py-0">
  <div class="category-wrapper row" [class.edit]="mode === 'edit'">
    <div class="col-12 track p-0">
      <div class="category-item">
        <div class="list-group list-group-flush mb-0">
          <button
            type="button"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            [class.active]="selectedCategory.length && selectedCategory[0].id === category.id"
            (click)="onChooseCategory(category, 0, index)"
            *ngFor="let category of category$ | async; let index = index"
          >
            {{ category.name }}
            <i class="ri-arrow-right-s-line ri-xl" *ngIf="category.children.length"></i>
          </button>
        </div>
        <div class="btn btn-outline-primary add-new-cat" *ngIf="mode === 'edit'" (click)="onAddNewCategory(null)">
          <i class="ri-add-line mt-2"></i> {{ 'addNew' | translate }}
        </div>
      </div>
      <div class="category-item position-relative" *ngFor="let children of childCategory; let indexChild = index">
        <div class="list-group list-group-flush mb-0">
          <button
            type="button"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            [class.active]="
              selectedCategory.length && selectedCategory[indexChild + 1] && selectedCategory[indexChild + 1].id === category.id
            "
            (click)="onChooseCategory(category, indexChild + 1, indexPos)"
            *ngFor="let category of children; let indexPos = index"
          >
            {{ category.name }}
            <i class="ri-arrow-right-s-line ri-xl" *ngIf="category.children.length"></i>
          </button>
        </div>
        <div class="btn btn-outline-primary add-new-cat" (click)="onAddNewCategory(indexChild)" *ngIf="mode === 'edit'">
          <i class="ri-add-line mt-2"></i> {{ 'addNew' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="mode === 'detail'">
  <button type="button" class="btn btn-primary" (click)="onContinue()">{{ 'continue' | translate }}</button>
</div>
