import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from 'src/app/models/menu-item';
import { map } from 'rxjs/operators';
import { UserDashboard } from 'src/app/views/roles/models/user.model';
import { PostalCode } from 'src/app/views/order/model/address.model';
import { Store as StoreModel } from 'src/app/views/merchant/models/store.model';
import { ResponseService } from '../response.service';
import { Response } from '../response.model';
import { Tag } from '../models/tag.model';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  loader = true;
  constructor(private http: ResponseService) {}
  getMenuList(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>('/navigation-menu').pipe(map((resp: Response<MenuItem[]>) => resp.data));
  }
  getMe(): Observable<UserDashboard> {
    return this.http.get<UserDashboard>('/user/me').pipe(map((resp: Response<UserDashboard>) => resp.data));
  }
  getTags(params: any): Observable<Response<Tag[]>> {
    return this.http.get<Tag[]>('/tags', false, { params }).pipe(map((resp: Response<Tag[]>) => resp));
  }
  getListPostalCode(params: any): Observable<Response<PostalCode[]>> {
    return this.http.get<PostalCode[]>('/address', true, { params }).pipe(map((resp: Response<PostalCode[]>) => resp));
  }
  getListStores(params: any): Observable<Response<StoreModel[]>> {
    return this.http.get<StoreModel[]>('/stores', true, { params }).pipe(map((resp: Response<StoreModel[]>) => resp));
  }
  createTags(tag) {
    return this.http.post('/tags', { tag });
  }
  getPlaceApi(keyword: string) {
    return this.http.get(`/places/${keyword}`, true);
  }
}
