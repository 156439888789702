import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: unknown[], callback: (item: unknown) => boolean): unknown {
    if (!items || !callback) {
      return items;
    }
    return items.filter((item) => callback(item));
  }
}

@Pipe({
  name: 'striphtml',
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string, limit: number = 0): any {
    if (!value) return value;
    const result = value.replace(/<.*?>/g, '');
    if (limit > 0) {
      return result.length > limit ? `${result.substring(0, limit)}...` : result;
    }
    return result;
  }
}
