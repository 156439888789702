import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { Store } from '@ngrx/store';
import { getTaptalkTicket } from 'src/app/views/auth-pages/store/auth.selectors';
import { AppState } from 'src/app/store/app.reducer';
import { Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/camelcase
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChooseProductComponent } from 'src/app/views/voucher/components/choose-product/choose-product.component';
import { Product } from 'src/app/views/product/model/product.model';
import { environment } from 'src/environments/environment';
import * as Taptalk from '@taptalk.io/web-sdk';
import { setUnreadChat } from 'src/app/views/auth-pages/store/auth.actions';
import { TaptalkProduct } from './taptalk.model';

@Component({
  selector: 'app-taptalk',
  templateUrl: './taptalk.component.html',
  styleUrls: ['./taptalk.component.scss'],
})
export class TaptalkComponent implements OnInit, OnDestroy {
  taptalkTicket = null;
  showTaptalk = false;
  subscription: Subscription;
  customerUrl = environment.customerUrl;
  onSetProduct = new Date().getTime();
  bsModalRef: BsModalRef;
  showChatRoomDetail = { open: false, idUser: '0' };
  constructor(public messagingService: MessagingService, private store: Store<AppState>, private modalService: BsModalService) {
    window.addEventListener('message', (e) => {
      if (e.data === 'openModalProductTaptalk') {
        this.openChooseProduct();
      }
      if (e.data === 'NewMessage') {
        this.getUndreadMessage();
      }
    });
    this.messagingService.taptalkModule = Taptalk;
    this.messagingService.taptalkModule.taptalk.init(
      environment.TAPTALK_APP_KEY_ID,
      environment.TAPTALK_APP_KEY_SECRET,
      environment.TAPTALK_APP_BASE_URL
    );
    this.subscription = this.store.select(getTaptalkTicket).subscribe((e) => {
      if (e) {
        this.taptalkTicket = e;
        if (this.messagingService.taptalkModule) {
          if (!this.messagingService.taptalkModule.taptalk.isAuthenticated()) {
            this.taptalkConnect(e);
          } else {
            this.taptalkConnectToWs();
          }
        }
      }
    });
  }
  getUndreadMessage() {
    let count = 0;
    if (this.messagingService.taptalkModule) {
      try {
        count = this.messagingService.taptalkModule.tapCoreRoomListManager.updateUnreadBadgeCount();
        console.log('count: ', count);
        this.messagingService.taptalkUnreadCounter = count;
        return this.store.dispatch(setUnreadChat({ unreadChat: count }));
      } catch (error) {
        return this.store.dispatch(setUnreadChat({ unreadChat: 0 }));
      }
    }
    return this.store.dispatch(setUnreadChat({ unreadChat: count }));
  }
  taptalkConnect(ticket) {
    this.messagingService.taptalkModule.taptalk.authenticateWithAuthTicket(ticket, true, {
      onSuccess: () => {
        this.taptalkConnectToWs();
        this.messagingService.taptalkModule.taptalk.addTapListener({
          onTapTalkRefreshTokenExpired: () => {
            this.taptalkConnect(this.taptalkTicket);
          },
        });
      },
      onError: (errorCode, errorMessage) => {
        console.log(errorCode, errorMessage);
      },
    });
  }
  taptalkConnectToWs() {
    this.messagingService.taptalkModule.taptalk.connect({
      onSuccess: (successMessage) => {
        console.log('success', successMessage);
        this.showTaptalk = true;
      },
      onError: (errorMessage) => {
        console.log('err', errorMessage);
        // do action here
      },
      onClose: (message) => {
        setTimeout(() => this.taptalkConnectToWs(), 5000);
        console.log('close', message);
      },
    });
  }
  ngOnInit(): void {}
  onClickedOutsideMessage() {
    this.messagingService.toggleCloseTaptalk();
  }
  openChooseProduct() {
    const initialState = {
      selectedProduct: [],
      singleMode: true,
    };
    this.bsModalRef = this.modalService.show(ChooseProductComponent, { class: 'mw-100 w-1000px', initialState, ignoreBackdropClick: true });
    this.bsModalRef.content.onClose.subscribe((productData: Product[]) => {
      if (productData.length > 0) {
        const choosenProduct = productData[0];
        const productPreview: TaptalkProduct = {
          type: 'product',
          primaryImage: choosenProduct.primaryImage,
          title: choosenProduct.title,
          retailPrice: choosenProduct.variants[0].originalPrice,
          slug: choosenProduct.slug,
        };
        sessionStorage.setItem('previewDataMessage', JSON.stringify(productPreview));
        this.onSetProduct = new Date().getTime();
      }
    });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    window.removeEventListener('message', () => {});
    sessionStorage.removeItem('activeRoomModel');
    sessionStorage.removeItem('previewDataMessage');
  }
}
