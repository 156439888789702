import { Category } from './category.model';
import { Variant } from './variant.model';

export interface Product {
  id?: number;
  title: string;
  description: string;
  used: boolean;
  active: boolean;
  options: string[];
  variants: Variant[];
  tags: string[];
  sale: boolean;
  status: string;
  images?: Image[];
  store?: { id: number; name: string; fulfillment: boolean };
  storeId: number;
  brand?: { id: number; name: string };
  brandId: number;
  categoryId: number;
  category?: Category;
  slug?: string;
  expanded?: boolean;
  attributes?: Attributes[];
  primaryImage?: string;
  isSelected?: boolean;
  isSelected2?: boolean;
  isDisabled?: boolean;
  brightspot?: boolean;
  isActive?: boolean;
}
export interface Image {
  id: number;
  type: string;
  filename: string;
  typeId: number;
  imageUrl: string;
  url?: string;
  primary?: boolean;
}

export interface ModerationHistory {
  id: number;
  moderationReason: string;
  productId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface Attributes {
  attributeId: number;
  value: string;
}

export enum ProductStatus {
  published = 'published',
  draft = 'draft',
  moderated = 'moderated',
  inReview = 'in_review',
}

export interface FulfillmentValidation {
  isLoading: boolean;
  error: boolean;
}

export enum ProductValumeAttribute {
  length = 'length',
  width = 'width',
  height = 'height',
}
