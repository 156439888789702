<div class="table-default">
  <div class="table-container">
    <div class="table-header d-flex align-items-center mb-3">
      <ng-content select="[checkbox]"></ng-content>
      <ng-container *ngFor="let header of tableHeader; let index = index">
        <ng-container *ngIf="header.children && header.children.length !== 0; else elseBlock">
          <div class="table-cells {{ header.class }}">
            <div
              class="table-cell {{ children.class }}"
              (click)="children.sort && onSort(children.sort['value'])"
              [class.hover-pointer]="children.sort"
              *ngFor="let children of header.children"
            >
              <div dropdown *ngIf="children.dropdown" class="position-relative">
                <div dropdownToggle class="d-flex align-items-center">
                  <ng-container *ngFor="let dropdown of children.dropdown['items'] | filter: filterActiveDropdown">
                    {{ dropdown.label | translate }} <i class="ri-arrow-down-s-line ri-xl"></i>
                  </ng-container>
                </div>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                  <li role="menuitem">
                    <ng-container *ngFor="let dropdown of children.dropdown['items'] | filter: filterDeActiveDropdown">
                      <a
                        class="dropdown-item"
                        (click)="onDropdownClicked(children.dropdown['value'], dropdown.value)"
                        href="javascript:void(0)"
                        >{{ dropdown.label | translate }}</a
                      >
                    </ng-container>
                  </li>
                </ul>
              </div>
              <ng-container *ngIf="!children.dropdown">
                {{ children.title | translate }}
              </ng-container>
              <div class="sort d-inline-block ml-1" *ngIf="children.sort">
                <i class="ri-arrow-up-s-fill up ri-xl" [class.active]="children.sort['status'] && children.sort['status'] === 'asc'"></i>
                <i
                  class="ri-arrow-down-s-fill down ri-xl"
                  [class.active]="children.sort['status'] && children.sort['status'] === 'desc'"
                ></i>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #elseBlock>
          <div class="table-cell {{ header.class }} position-relative" (click)="header.sort && onSort(header.sort['value'])">
            <div dropdown *ngIf="header.dropdown" class="position-relative">
              <div dropdownToggle class="d-flex align-items-center">
                <ng-container *ngFor="let dropdown of header.dropdown['items'] | filter: filterActiveDropdown">
                  {{ dropdown.label | translate }} <i class="ri-arrow-down-s-line ri-xl"></i>
                </ng-container>
              </div>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem">
                  <ng-container *ngFor="let dropdown of header.dropdown['items'] | filter: filterDeActiveDropdown">
                    <a
                      class="dropdown-item"
                      (click)="onDropdownClicked(header.dropdown['value'], dropdown.value)"
                      href="javascript:void(0)"
                      >{{ dropdown.label | translate }}</a
                    >
                  </ng-container>
                </li>
              </ul>
            </div>
            <ng-container *ngIf="!header.dropdown">
              {{ header.title | translate }}
            </ng-container>
            <div class="sort d-inline-block ml-1" *ngIf="header.sort">
              <i class="ri-arrow-up-s-fill up ri-xl" [class.active]="header.sort['status'] && header.sort['status'] === 'asc'"></i>
              <i class="ri-arrow-down-s-fill down ri-xl" [class.active]="header.sort['status'] && header.sort['status'] === 'desc'"></i>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <ng-content></ng-content>
    <div
      class="d-flex justify-content-start justify-content-lg-center mt-4"
      *ngIf="pagination && pagination.totalItems !== 0 && showPagination"
    >
      <pagination
        *ngIf="pagination && pagination.totalItems !== 0"
        [totalItems]="pagination.totalItems"
        [(ngModel)]="page"
        [rotate]="false"
        [itemsPerPage]="pagination.itemsPerPage"
        [maxSize]="5"
        [customNextTemplate]="nextTemplate"
        [customPreviousTemplate]="prevTemplate"
        (pageChanged)="pageChanged($event)"
      ></pagination>
      <ng-template #nextTemplate let-disabled="disabled" let-currentPage="page">
        <i class="ri-arrow-right-s-line ri-2x"></i>
      </ng-template>
      <ng-template #prevTemplate let-disabled="disabled" let-currentPage="page">
        <i class="ri-arrow-left-s-line ri-2x"></i>
      </ng-template>
    </div>
  </div>
</div>
