/* eslint-disable import/no-extraneous-dependencies */
import { BrowserModule } from '@angular/platform-browser';
import { isDevMode, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularReactBrowserModule } from '@angular-react/core';
import { StoreModule } from '@ngrx/store';
import { EntityDataModule, DefaultDataServiceConfig, PersistenceResultHandler, EntityCollectionReducerMethodsFactory } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgsgModule } from 'ng-sortgrid';
import { NgxBarcodeModule } from 'ngx-barcode';

import { ClickOutsideModule } from 'ng-click-outside';
import { AgmCoreModule } from '@agm/core';
import { NgApexchartsModule } from 'ng-apexcharts';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/remote-config';

import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SnackbarModule } from 'ngx-snackbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { QuillModule } from 'ngx-quill';

import { ToastrModule } from 'ngx-toastr';
import { TaptalkReactModule } from 'taptalk-react';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';

import { SofboxModule } from './sofbox/sofbox.module';
import { TheGoodsModule } from './the-goods/the-goods.module';

import { AppComponent } from './app.component';

import { metaReducers, reducers } from './store/app.reducer';
import { AuthEffects } from './views/auth-pages/store/auth.effects';
import { InboxEffects } from './views/inbox/store/inbox.effects';
import { I18nEffects } from './i18n/i18n.effects';

import { AuthInterceptorService } from './views/auth-pages/auth-interceptor.service';
import { AdditionalPersistenceResultHandler } from './store/AdditionalPersistenceResultHandler.service';
import { AdditionalEntityCollectionReducerMethodsFactory } from './store/AdditionalEntityCollectionReducerMethodFactory.service';

import { I18nModule } from './i18n/i18n.module';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.apiUrl,
};
export const pluralNames = {
  // Case matters. Match the case of the entity name.
  Hero: 'Hero',
  User: 'User',
  FlashSale: 'limited-offer',
  StoreLocator: 'store-locator',
  ProductGroup: 'product-groups',
  News: 'News',
  Notification: 'notification-campaign',
  CategoryNews: 'categories',
  BankPromo: 'bank-promos',
  MassUpdateDownload: 'download',
  MassUpdateUpload: 'upload',
  PromoDiscount: 'store-discounts',
  ProductModeration: 'products',
  TieredDiscount: 'discounts',
  SelectAllBrand: 'select_all',
  SelectAllProduct: 'select_all',
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularReactBrowserModule,
    TaptalkReactModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    QuillModule.forRoot(),
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    CollapseModule.forRoot(),
    SofboxModule,
    TheGoodsModule,
    NgSelectModule,
    SnackbarModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AuthEffects, I18nEffects, InboxEffects]),
    EntityDataModule.forRoot({ pluralNames }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    I18nModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireRemoteConfigModule,
    AngularFireModule.initializeApp(environment.firebase),
    AgmCoreModule.forRoot({
      apiKey: environment.gmapApiKey,
      libraries: ['places'],
    }),
    CKEditorModule,
    ClickOutsideModule,
    ToastrModule.forRoot({
      iconClasses: {
        error: 'tgd-toast tgd-toast-error',
        info: 'tgd-toast tgd-toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      },
    }),
    NgsgModule,
    NgxBarcodeModule,
    NgApexchartsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    { provide: PersistenceResultHandler, useClass: AdditionalPersistenceResultHandler },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: AdditionalEntityCollectionReducerMethodsFactory,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: SETTINGS,
      useFactory: () => (isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}),
    },
    { provide: Window, useValue: window },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
