/* eslint-disable no-plusplus */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { Pagination, TableHeader } from 'src/app/shared/table/table.model';
import { CategorySelectorComponent } from 'src/app/views/product/components/category-selector/category-selector.component';
import { SelectedCategory } from 'src/app/views/product/model/category.model';
import { Product, ProductStatus } from 'src/app/views/product/model/product.model';
import { ProductEntityService } from 'src/app/views/product/services/product-entity.service';

@Component({
  selector: 'app-choose-product',
  templateUrl: './choose-product.component.html',
  styleUrls: ['./choose-product.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChooseProductComponent implements OnInit {
  public onClose: Subject<any[]> = new Subject();
  selectedProduct: Product[] = [];
  selectedProductIds: number[] = [];
  productFilter: FormGroup;
  productSearch: FormControl = new FormControl(null, []);
  productCategory: FormControl = new FormControl(null, []);
  selectedCategory: SelectedCategory[] = [];
  products: Product[] = [];
  pagination: Pagination;
  bsModalRef2: BsModalRef;
  loading$: Observable<boolean> = of(true);
  mode = 'create';
  tableHeader: TableHeader[] = [
    {
      title: '',
      sort: false,
      class: 'table-cell-choose-action',
    },
    {
      title: 'productName',
      sort: false,
      class: 'table-cell-name',
    },
    {
      title: 'parent',
      sort: false,
      children: [
        {
          title: 'variant',
          sort: false,
        },
        {
          title: 'price',
          sort: {
            value: 'variants.price',
            status: false,
          },
        },
        {
          title: 'stock',
          sort: {
            value: 'variants.stock',
            status: false,
          },
        },
      ],
    },
  ];
  masterSelected: boolean;
  loaded = false;
  subscriptionSelector: Subscription;
  subscriptionSelector2: Subscription;
  subscriptionSelector3: Subscription;
  singleMode = false;
  enumProductStatus = ProductStatus;
  storeId = null;
  constructor(
    protected bsModalRef: BsModalRef,
    protected modalService: BsModalService,
    protected formBuilder: FormBuilder,
    protected trackingService: TrackingService,
    protected productEntityService: ProductEntityService
  ) {
    this.buildFilter();
  }
  ngOnInit(): void {
    this.subscribeInit();
  }
  subscribeInit() {
    this.productFilter.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      this.pagination.currentPage = 1;
      this.getProduct();
    });
    this.subscriptionSelector2 = this.productEntityService.loaded$.subscribe((loaded) => {
      if (!loaded) {
        this.pagination = {
          currentPage: 1,
          totalItems: 0,
          itemsPerPage: 30,
        };
        setTimeout(() => {
          this.getProduct();
        });
      }
    });
    this.loading$ = this.productEntityService.loading$;
    this.subscribePagination();
  }
  subscribePagination() {
    this.subscriptionSelector = this.productEntityService.selectors$.collection$.subscribe((e: any) => {
      if (e.pagination && e.loaded) {
        this.pagination = {
          currentPage: e.pagination.currentPage,
          totalItems: e.pagination.itemCount,
          itemsPerPage: e.pagination.itemsPerPage,
        };
      }
    });
  }
  onContinue() {
    const data =
      this.selectedProduct.length === 0 && this.selectedProductIds.length !== 0 && this.mode === 'edit'
        ? this.selectedProductIds
        : this.selectedProduct;
    this.onClose.next(data);
    this.unsubscribes();
    this.bsModalRef.hide();
  }
  close() {
    this.unsubscribes();
    this.bsModalRef.hide();
  }
  unsubscribes() {
    if (this.subscriptionSelector3) {
      this.subscriptionSelector3.unsubscribe();
    }
    if (this.subscriptionSelector2) {
      this.subscriptionSelector2.unsubscribe();
    }
    if (this.subscriptionSelector) {
      this.subscriptionSelector.unsubscribe();
    }
    this.productEntityService.setLoaded(false);
    this.productEntityService.clearCache();
  }
  onClearCategory() {
    this.selectedCategory = [];
    this.productCategory.setValue(null);
  }
  openCategorySelector() {
    const initialState = {
      selectedCategory: this.selectedCategory,
    };
    this.bsModalRef2 = this.modalService.show(CategorySelectorComponent, {
      id: 4,
      class: 'mw-100 w-50',
      initialState,
      ignoreBackdropClick: true,
    });
    this.bsModalRef2.content.onClose.subscribe((category: SelectedCategory[]) => {
      if (category.length) {
        const lastSelectedCategory = category[category.length - 1].id;
        this.productCategory.setValue(lastSelectedCategory);
      } else {
        this.productCategory.setValue(null);
      }
      this.selectedCategory = category;
    });
  }
  checkUncheckAll() {
    if (this.singleMode) {
      this.products[0].isSelected = true;
    } else {
      for (let i = 0; i < this.products.length; i++) {
        this.products[i].isSelected = this.masterSelected;
      }
    }
    this.getCheckedItemList();
    if (this.singleMode) {
      this.disableEnebleItemList();
    }
  }
  isAllSelected() {
    this.masterSelected = this.products.every((item: any) => {
      return item.isSelected === true;
    });

    this.getCheckedItemList();
    if (this.singleMode) {
      this.disableEnebleItemList();
    }
  }
  onExpandVariant(index) {
    const cloneProducts = { ...this.products[index] };
    cloneProducts.expanded = !cloneProducts.expanded;
    this.products[index] = cloneProducts;
  }
  getCheckedItemList() {
    if (this.selectedProduct.length === 0 && this.selectedProductIds.length !== 0) {
      let selectedProductIds = [...this.selectedProductIds];
      for (let i = 0; i < this.products.length; i++) {
        const findIndex = this.selectedProductIds.indexOf(this.products[i].id);
        if (this.products[i].isSelected && findIndex === -1) {
          selectedProductIds.push(this.products[i].id);
        } else if (!this.products[i].isSelected && findIndex !== -1) {
          selectedProductIds = selectedProductIds.filter((e) => {
            return e !== this.products[i].id;
          });
        }
      }
      this.selectedProductIds = selectedProductIds;
    } else {
      let selectedProduct = [...this.selectedProduct];
      for (let i = 0; i < this.products.length; i++) {
        const findIndex = this.selectedProduct.findIndex((e) => e.id === this.products[i].id);
        if (this.products[i].isSelected && findIndex === -1) {
          selectedProduct.unshift(this.products[i]);
        } else if (!this.products[i].isSelected && findIndex !== -1) {
          selectedProduct = selectedProduct.filter((e) => {
            return e.id !== this.selectedProduct[findIndex].id;
          });
        }
      }
      this.selectedProduct = selectedProduct;
    }
  }
  disableEnebleItemList() {
    if (this.selectedProduct.length === 1) {
      this.products = this.products.map((e) => {
        const cloneProduct = { ...e };
        if (e.id !== this.selectedProduct[0].id) {
          cloneProduct.isDisabled = true;
        }
        return cloneProduct;
      });
    } else if (this.selectedProduct.length === 0) {
      this.products = this.products.map((e) => {
        const cloneProduct = { ...e };
        cloneProduct.isDisabled = false;
        return cloneProduct;
      });
    }
  }
  buildFilter() {
    this.productFilter = this.formBuilder.group({
      productSearch: this.productSearch,
      productCategory: this.productCategory,
    });
  }
  pageChanges(page) {
    this.pagination.currentPage = page;
    this.masterSelected = false;
    this.getProduct();
  }
  getProduct() {
    const param: any = {};
    if (this.productSearch.value) {
      param.q = this.productSearch.value.toString();
    }
    if (this.productCategory.value) {
      param['category.id'] = this.productCategory.value.toString();
    }
    if (this.pagination.currentPage) {
      param.page = this.pagination.currentPage.toString();
    }

    if (this.storeId) {
      param['store.id'] = this.storeId;
    }
    param.limit = this.pagination.itemsPerPage.toString();
    this.subscriptionSelector3 = this.productEntityService.getWithQuery(param).subscribe(
      (products) => {
        const productWithPrimary = products.map((product) => {
          const cloneProduct = { ...product };
          if (cloneProduct.images.length > 0) {
            let primaryImage = cloneProduct.images[0].imageUrl;
            const index = cloneProduct.images.findIndex((e) => e.primary === true);
            if (index !== -1) {
              primaryImage = cloneProduct.images[index].imageUrl ? cloneProduct.images[index].imageUrl : cloneProduct.images[index].url;
            }
            cloneProduct.primaryImage = primaryImage;
          }
          let findIndexSelected = null;
          if (this.selectedProduct.length === 0 && this.selectedProductIds.length !== 0) {
            findIndexSelected = this.selectedProductIds.includes(cloneProduct.id);
          } else {
            findIndexSelected = this.selectedProduct.find((e) => e.id === cloneProduct.id);
          }

          if (findIndexSelected) {
            cloneProduct.isSelected = true;
          } else {
            cloneProduct.isSelected = false;
          }
          return { ...cloneProduct };
        });
        this.products = [...productWithPrimary];
        this.trackingService.tracking('Choose Product', 'view', 'success', JSON.stringify(param)).subscribe();
        setTimeout(() => {
          this.loaded = true;
        }, 1000);
        this.productEntityService.setLoaded(true);
        if (this.singleMode) {
          this.disableEnebleItemList();
        }
      },
      (err) => {
        this.trackingService.tracking('Choose Product', 'view', 'error', JSON.stringify(param), JSON.stringify(err.error)).subscribe();
      }
    );
  }
}
