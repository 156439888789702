/* eslint-disable @typescript-eslint/camelcase */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../../environments/environment';
import { User } from '../auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}
  forgotPassword(email) {
    const body = {
      email,
    };
    return this.http.post(`${environment.apiUrlDef}/accounts/admin/forgot-password`, body);
  }
  resetPassword(resetCode, newPassword) {
    const body = {
      resetCode,
      newPassword,
    };
    return this.http.post(`${environment.apiUrlDef}/accounts/admin/reset-password`, body);
  }
  createUserData(userData, email = null, id = null) {
    const decodeJwt = jwt_decode(userData.accessToken);
    console.log('decodeJwt: ', decodeJwt);
    const data = {
      ...new User(
        email || userData.email,
        id || userData.id,
        userData.refreshToken,
        userData.accessToken,
        new Date((decodeJwt as any).exp * 1000).toString(),
        (decodeJwt as any).taptalk_ticket
      ),
    };
    return data;
  }
}
