/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, switchMap, tap, take } from 'rxjs/operators';
import { Observable, Subject, throwError, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { detectDevice } from 'src/app/shared/helper';
import { Router } from '@angular/router';
import { SettingService } from 'src/app/shared/services/setting.service';
import { environment } from '../../../environments/environment';
import { AppState } from '../../store/app.reducer';
import * as AuthActions from './store/auth.actions';

import { User } from './auth.model';
import { getUser } from './store/auth.selectors';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private refreshSubject: Subject<any> = new Subject<any>();
  tokenBasic = window.btoa(`${environment.USERNAME_BASIC_AUTH}:${environment.PASSWORD_BASIC_AUTH}`);
  user: User;
  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private settingService: SettingService
  ) {
    this.store.pipe(select(getUser)).subscribe((user) => {
      this.user = user;
    });
  }

  private getRefreshToken() {
    const headers = new HttpHeaders();
    headers.append('authentication', `Basic ${this.tokenBasic}`);
    if (this.user) {
      const data = {
        grantType: 'refresh_token',
        identifier: this.user.accessToken,
        password: this.user.refreshToken,
      };
      return this.http.post(`${environment.apiUrlDef}/accounts/login`, data, { headers }).pipe(
        catchError((errorRes) => {
          this.store.dispatch(AuthActions.logout());
          setTimeout(() => {
            this.settingService.loader = false;
          }, 1000);
          this.router.navigate(['auth/sign-in']);
          return throwError(errorRes);
        })
      );
    }
    return of();
  }

  private ifTokenExpired() {
    this.refreshSubject.subscribe({
      complete: () => {
        this.refreshSubject = new Subject<any>();
        setTimeout(() => {
          this.settingService.loader = false;
        }, 1000);
      },
    });
    if (this.user && this.refreshSubject.observers.length === 1) {
      this.getRefreshToken()
        .pipe(
          tap((e: any) => {
            const userData = e;
            const loadedUser = this.authService.createUserData(userData, this.user.email, this.user.id);
            localStorage.setItem('userData', JSON.stringify(loadedUser));
            this.store.dispatch(AuthActions.authenticateSuccess({ user: loadedUser, redirect: false }));
            setTimeout(() => {
              this.settingService.loader = false;
            }, 1000);
          })
        )
        .subscribe(this.refreshSubject);
    }
    return this.refreshSubject;
  }
  private checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    return (error.status && error.status === 401) || (error.error && error.error.message === 'TokenExpired');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/login')) {
      if ((req.body && req.body.grantType === 'admin') || (req.body && req.body.grantType === 'refresh_token')) {
        return next.handle(this.updateHeader(req, false));
      }
    } else if (req.url.endsWith('/tracker')) {
      let user = null;
      this.store
        .pipe(
          select(getUser),
          take(1),
          tap((u) => {
            user = u;
          })
        )
        .subscribe();
      if (user === null) {
        return this.guest().pipe(
          switchMap((e) => {
            if ((e as any).accessToken) {
              return next.handle(this.updateHeader(req, false, (e as any).accessToken));
            }
          })
        );
      }
    }
    return next.handle(this.updateHeader(req)).pipe(
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          if (this.checkTokenExpiryErr(error)) {
            return this.ifTokenExpired().pipe(
              switchMap(() => {
                return next.handle(this.updateHeader(req));
              })
            );
          }
          return throwError(error);
        }
        return caught;
      })
    );
  }
  updateHeader(req, change = true, guest = false) {
    let Authorization = `Basic ${this.tokenBasic}`;
    if (this.user && change && !guest) {
      Authorization = `Bearer ${this.user.accessToken}`;
    }
    if (guest) {
      Authorization = `Bearer ${guest}`;
    }
    const device = detectDevice();
    const platform = environment.HEADERS_PLATFORM || '';
    const language: any = 'en';
    const buildNumber = environment.HEADERS_BUILD_NUMBER || '';
    const buildVersion = environment.HEADERS_BUILD_VERSION || '';
    const headers = req.headers
      .set('Authorization', Authorization)
      .set('Platform', platform)
      .set('Language', language)
      .set('Build-Version', buildVersion)
      .set('Build-Number', buildNumber)
      .set('device', device);

    const modifiedReq = req.clone({
      headers,
    });
    return modifiedReq;
  }

  guest() {
    const body = {
      grantType: 'guest',
    };
    return this.http.post(`${environment.apiUrlDef}/accounts/login`, body);
  }
}
