import { Injectable } from '@angular/core';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Pagination } from 'src/app/shared/table/table.model';
import { InboxService } from '../services/inbox.service';
import * as InboxActions from './inbox.actions';

@Injectable()
export class InboxEffects {
  inboxGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.loadInbox),
      mergeMap((action) =>
        this.inboxService.getInbox(action.params).pipe(
          map(
            (resp) => {
              const metadataResp: {
                totalItems: number;
                currentPage: number;
                itemsPerPage: number;
              } = resp.metadata;
              const pagination: Pagination = {
                currentPage: metadataResp.currentPage,
                totalItems: metadataResp.totalItems,
                itemsPerPage: metadataResp.itemsPerPage,
              };
              return InboxActions.loadInboxSuccess({ inbox: resp.data, pagination });
            },
            catchError(() => of(InboxActions.loadInboxError({ errorMessage: 'Error Load Inbox' })))
          )
        )
      )
    )
  );
  inboxRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.readInbox),
      mergeMap((action) =>
        this.inboxService.readInbox(action.id).pipe(
          map(
            () => {
              return InboxActions.readInboxSuccess({ id: action.id });
            },
            catchError(() => of(InboxActions.loadInboxError({ errorMessage: 'Error Read Inbox' })))
          )
        )
      )
    )
  );
  getUnreadCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.loadUnreadCount),
      mergeMap(() =>
        this.inboxService.getInboxCounter().pipe(
          map(
            (resp) => {
              return InboxActions.loadUnreadCountSuccess({ unread: +(resp.data as { unread: number }).unread });
            },
            catchError(() => of(InboxActions.loadInboxError({ errorMessage: 'Error Get Unread Count' })))
          )
        )
      )
    )
  );
  constructor(private actions$: Actions, private inboxService: InboxService) {}
}
