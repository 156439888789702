export const environment = {
  production: true,
  apiUrl: 'https://api.stg.thegoodsdept.com/api/v1/admin',
  apiUrlDef: 'https://api.stg.thegoodsdept.com/api',
  apiaryUrl: 'https://private-anon-0afbc7006a-goodsdept.apiary-mock.com',
  storageUrl: 'https://storage.googleapis.com/image-stg-thegoodsdept',
  USERNAME_BASIC_AUTH: 'android',
  PASSWORD_BASIC_AUTH: 'secret',
  HEADERS_PLATFORM: 'web',
  HEADERS_LANGUAGE: 'en',
  HEADERS_BUILD_VERSION: '1.0',
  HEADERS_BUILD_NUMBER: '1',
  gmapApiKey: 'AIzaSyAmtSYjoTqGhD41KrzsP7Dh3WSSFM_AtHo',
  customerUrl: 'https://stg.thegoodsdept.com',
  bucket: 'image-stg-thegoodsdept',
  appshotUrl: 'https://thegoodsdept.appspot.com',
  merchantRoleId: '5',
  goodsStoreId: '1',
  checkResiUrl: 'https://cekresi.com/?noresi=',
  loyaltyCategoryId: '92',
  TAPTALK_APP_KEY_ID: '00cba7df12cd0393e47cdef93',
  TAPTALK_APP_KEY_SECRET: 'MjQyOTY0QDk4LjE2Ni4xMDNAMTYwOTc1NTQ4OQ/YTRiMDk2MzQ/NzhmNjQyODU4YzZl/ZjJiZDJhMmM4Mjdl',
  TAPTALK_APP_BASE_URL: 'https://e-psx-5c57961d76a112ffecc172e1009813ce.io.taptalk.io',
  firebase: {
    apiKey: 'AIzaSyB9Mk97P-i5Zj-BIXuYlZ-m0pmavFMacFs',
    authDomain: 'thegoodsdept-89777.firebaseapp.com',
    databaseURL: 'https://thegoodsdept-89777.firebaseio.com',
    projectId: 'thegoodsdept-89777',
    storageBucket: 'thegoodsdept-89777.appspot.com',
    messagingSenderId: '287282967410',
    appId: '1:287282967410:web:0117ffe5daa7b29ff223eb',
    measurementId: 'G-BW79PMJ5CT',
  }
};
