import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  InputRendererOptions,
  JsxRenderFunc,
  ReactWrapperComponent,
} from '@angular-react/core';
import { TaptalkReactProps } from './taptalk-react.model';

@Component({
  selector: 'lib-taptalk-react',
  exportAs: 'libTaptalkReact',
  template: `
    <FloatChat
      #reactNode
      [containerHeight]="containerHeight"
      [isDashboard]="isDashboard"
      [showRoom]="showRoom"
      [taptalk]="taptalk"
      [siteURL]="siteURL"
      [setProduct]="setProduct"
      [showChatRoomDetail]="showChatRoomDetail"

    ></FloatChat>
  `,
  styles: ['react-renderer'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaptalkReactComponent extends ReactWrapperComponent<TaptalkReactProps> implements OnInit {
  @ViewChild('reactNode') protected reactNodeRef: ElementRef;
  @Input() containerHeight: TaptalkReactProps['containerHeight'] = "70vh";
  @Input() isDashboard: TaptalkReactProps['isDashboard'] = true;
  @Input() showRoom: TaptalkReactProps['showRoom'] = false;
  @Input() taptalk: TaptalkReactProps['taptalk'] = null;
  @Input() siteURL: TaptalkReactProps['siteURL'] = '';
  @Input() setProduct: TaptalkReactProps['setProduct'];
  @Input() showChatRoomDetail: TaptalkReactProps['showChatRoomDetail'];

  @Input() renderChat?: InputRendererOptions<TaptalkReactProps>;

  onRenderChat: (props?: TaptalkReactProps, defaultRender?: JsxRenderFunc<TaptalkReactProps>) => JSX.Element;

  constructor(
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    renderer: Renderer2
  ) {
    super(elementRef, changeDetectorRef, renderer, { setHostDisplay: true });
  }

  ngOnInit(): void {
    if (this.taptalk) {
      this.onRenderChat = this.createRenderPropHandler(this.renderChat);
    }
  }

}
