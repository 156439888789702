import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaptalkReactModule } from 'taptalk-react';

import { ClickOutsideModule } from 'ng-click-outside';
import { EntityMetadataMap, EntityDefinitionService } from '@ngrx/data';
import { LoaderComponent } from './loader/loader.component';

import { LoaderContainerComponent } from './loader-container/loader-container.component';
import { TaptalkComponent } from './taptalk/taptalk.component';

const entityMetadata: EntityMetadataMap = {
  Hero: {},
  Product: {},
  Category: {},
};

@NgModule({
  declarations: [LoaderComponent, LoaderContainerComponent, TaptalkComponent],
  exports: [LoaderComponent, LoaderContainerComponent, TaptalkComponent],
  imports: [CommonModule, TaptalkReactModule, ClickOutsideModule],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [{ provide: Window, useValue: window }],
})
export class TheGoodsModule {
  constructor(private eds: EntityDefinitionService) {
    eds.registerMetadataMap(entityMetadata);
  }
}
