import { createReducer, on } from '@ngrx/store';
import { Language } from '../models/language';
import * as I18nActions from './i18n.actions';

export interface I18nState {
  languages: Language[];
  language: string;
}

export const initialState: I18nState = {
  languages: [
    new Language({ title: 'Indonesia', value: 'id', image: './assets/images/small/indonesia.png' }),
    new Language({ title: 'English', value: 'en', image: './assets/images/small/flag-01.png' }),
  ],
  language: 'id',
};

export const i18nReducer = createReducer(
  initialState,

  on(I18nActions.setLanguage, (state, action) => ({
    ...state,
    language: action.language,
  }))
);
