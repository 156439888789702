/* eslint-disable prettier/prettier */
export const id = {
  "sidebar": {
    "dashboard": "DASHBOARD",
    "orders": "PESANAN",
    "myOrders": "Pesanan saya",
    "returnCancelation": "Pembatalan / Pengembalian",
    "product": "PRODUK",
    "myProduct": "Produk Saya",
    "createProduct": "Tambah Produk",
    "promotion": "PROMOSI",
    "myVoucher": "Voucher saya",
    "createVoucher": "Tambah Voucher",
    "voucherReferralList" : "Voucher Referral",
    "createVoucherReferral" : "Tambah Voucher Referral",
    "store": "TOKO",
    "news": "BERITA",
    "newsList": "Daftar Berita",
    "createNews": "Buat Berita",
    "newsCategoryList": "Kategori Berita",
    "createNewsCategory": "Buat Kategori Berita",
    "review": "Penilaian Toko",
    "storeProfile": "PROFIL TOKO",
    "categories": "Kategori Toko",
    "setting": "PENGATURAN",
    "shipping": "Pengiriman",
    "address": "Alamat",
    "payments": "Pembayaran",
    "banner": "BANNER",
    "reports": "LAPORAN",
    "manageCategory": "KATEGORI",
    "wishlist" : "Wishlist",
    "customer" : "PELANGGAN",
    "roles" : "ROLE",
    "roleList": "Daftar Role",
    "userList" : 'Daftar User',
    "createUser": 'Buat User',
    "archetype" : 'ARCHETYPE',
    "archetypeList" : 'Daftar Archetype',
    "createArchetype" : 'Buat Archetype',
    "customerList" : 'Daftar Pelanggan',
    "createRole" : 'Buat Role',
    "myFlashSale" : "Penawaran Terbatas",
    "createFlashSale": "Tambah Penawaran Terbatas",
    "storeLocatorList": 'Lokasi Toko',
    "createStoreLocator": 'Buat Lokasi Toko',
    "brand": "BRAND",
    "brandList": "Daftar Brand",
    "createBrand": "Buat Brand",
    "groupCategoryList": 'GRUP KATEGORI',
    "createGroupCategory": 'Buat Grup Kategori',
    "deals": "Deals",
    "createDeals" : "Buat Deals Baru",
    "productDiscussion" : "DISKUSI PRODUK",
    "discussions" : "Diskusi",
    "notifications" : "NOTIFIKASI",
    "createNewNotifications" : "Buat Notifikasi Baru",
    "notificationList" : "Daftar Notifikasi",
    "productReview": "ULASAN PRODUK",
    "merchantStore" : "TOKO PENJUAL",
    "createMerchantStore" : "Buat Toko Penjual",
    "merchantStoreList": "Daftar Toko Penjual",
    "emailNotification": "Notifikasi Pesanan",
    "voucherBankPromo": "Promo Bank",
    "chat": "Chat",
    "referral" : "Program Referral",
    "promoDiscount": "Diskon Toko",
    "membership": "MEMBERSHIP",
    "setupMembership": "Atur Membership",
    "paymentStatusMembership": "Status Pembayaran",
    "productModeration": "Moderasi Produk",
    "tieredDiscount": "Diskon Bertingkat",
  },
  "signOut" : "Keluar",
  "product" : "Produk",
  "addNewProduct" : "Tambah Produk Baru",
  "productName" : "Nama Produk",
  "variant" : "Variasi",
  "price" : "Harga",
  "stock" : "Stok",
  "action" : "Aksi",
  "searchPlaceholder" : "Cari produk . . .",
  "selectCategory" : "Pilih kategori",
  "seeMoreVariant" : "Lihat {{value}} variasi lagi",
  "edit": "Ubah",
  "add" : "Tambah",
  "basicInformation" : "Informasi Produk",
  "productNamePlaceholder" : "Masukan nama produk anda",
  "productDescriptionPlaceholder" : "Masukan deskripsi produk anda",
  "productBrandPlaceholder" : "Pilih brand produk anda",
  "isRequired" : "tidak boleh kosong",
  "isInvalid" : "tidak benar",
  "productDescription" : "Deskripsi Produk",
  "productCategory" : "Kategori Produk",
  "category" : "Kategori",
  "productBrand" : "Brand Produk",
  "productCondition" : "Kondisi Produk",
  "used" : "Bekas",
  "new": "Baru",
  "salesInformation" : "Informasi Penjualan",
  "productPrice" : "Harga Produk",
  "productPricePlaceholder" : "Masukan harga produk anda",
  "productStock" : "Stok Produk",
  "productStockPlaceholder" : "Masukan stok produk anda",
  "productWeight" : "Berat Produk",
  "productWeightPlaceholder": "Masukan berat produk anda",
  "weight": "Berat",
  "productSKU" : "Product SKU",
  "productSKUPlaceholder": "Product SKU",
  'retailPrice': 'Harga Retail',
  'productRetailPrice' : 'Harga Retail Produk',
  'productRetailPricePlaceholder' : 'Masukan harga retail produk anda',
  "activeVariant" : "Aktifasi variasi",
  "name" : "Nama",
  "variantNamePlaceholder" : "Masukan nama variasi anda",
  "thisField" : "Kolom ini",
  "options" : "Pilihan",
  "option" : "Pilihan",
  "addOption" : "Tambah pilihan",
  "addVariant" : "Tambah variasi",
  "applyToAll" : "Terapkan ke semua",
  "variantList" : "Daftar Variasi",
  "mainPhoto" : "Foto Utama",
  "photo" : "Foto",
  "continue" : "Lanjutkan",
  "pending" : "Menunggu",
  "all": "Semua",
  "allStatus" : "Semua Status",
  "notPaid" : "Belum Bayar",
  "needToBeShipped" : "Perlu Dikirim",
  "preparingOrder" : "Mempersiapkan Pesanan",
  "preparing_order" : "Mempersiapkan Pesanan",
  "shipping" : "Dikirim",
  "delivering" : "Dikirim",
  "shipped" : "Sampai",
  "completed" : "Selesai",
  "orders" : "Pesanan",
  "order" : "Pesanan",
  "customer" : "Customer",
  "payment" : "Pembayaran",
  "orderNo" : "No Pesanan",
  "allShipping" : "Semua Jasa",
  "callShipping" : "Atur Pengiriman",
  "open" : "Buka",
  "close": "Tutup",
  "arrangeShipment" : "Atur Pengiriman",
  "confirm": "Konfirmasi",
  "address" : "Alamat",
  "date" : "Tanggal",
  "time": "Waktu",
  "shippingInformation" : "Informasi Jasa Kirim",
  "notPaidDescription": "Pesanan akan dibatalkan bila pembayaran tidak dilakukan sampai {{value}}. Silakan tunggu sampai pembayaran dikonfirmasi sebelum mengirimkan barang.",
  "totalOrder" : "Total Pesanan",
  "shippingTotal" : "Ongkos Kirim",
  "adminFee": "Biaya Admin",
  "totalSelling": "Total Penjualan",
  "shippingDiscount" : "Diskon Ongkos Kirim",
  "discount" : "Diskon",
  "totalCustomerPayment": "Total Pembayaran Customer",
  "buyerPayment" : "Pembayaran Customer",
  "paymentInformation": "Informasi Pembayaran",
  "seeDetail": "Lihat Rincian",
  "paymentReceived" : "Pembayaran Diterima",
  "payment_verified" : "Pembayaran Diterima",
  "orderCreated" : "Pesanan Dibuat",
  "pendingForPayment": "Belum Bayar",
  "cancel": "Batal",
  "cancelOrder" : "Batalkan Pesanan",
  "cancelCustomer" : "Dibatalkan Customer",
  "cancelAdmin" : "Dibatalkan Admin",
  "cancelShipping": "Dibatalkan Jasa",
  "cancel_shipping": "Dibatalkan Jasa",
  "cancelCustomerDescription" : "Pesanan dibatalkan oleh Customer.",
  "cancelAdminDescription" : "Pesanan dibatalkan oleh Admin.",
  "cancelShippingDescription" : "Pesanan dibatalkan oleh Jasa Pengirim.",
  "expired" : "Kadaluarsa",
  "rejected": "Ditolak",
  "orderCreatedDescription": "Checkout pesanan berhasil.",
  "cancelDescription" : "Pesanan dibatalkan.",
  "expiredDescription": "Pesanan dibatalkan karena Customer gagal melakukan pembayaran tepat waktu.",
  "rejectedDescription" : "Pembayaran pesanan ditolak.",
  "paymentReceivedDescription": "Pembayaran berhasil, customer menunggu barang untuk dikirim.",
  "whatYouCanDoNext": "APA YANG BISA ANDA LAKUKAN SELANJUTANYA",
  "processOrder": "Proses Pesanan",
  "processOrderConfirmMessage": "Apakah anda yakin ingin memproses pesanan ini ?",
  "cancelOrderConfirmMessage": "Apakah anda yakin ingin membatalkan pesanan ini ?",
  "acceptComplainOrderConfirmMessage": "Apakah anda yakin ingin menyetujui komplain ?",
  "rejectComplainOrderConfirmMessage": "Apakah anda yakin ingin menolak komplain ?",
  "returnOrderConfirmMessage" : "Apakah anda yakin ingin mengubah status order ini sebagai return ?",
  "yes" : "Ya",
  "no": "Tidak",
  "requestAirwayBillAnteraja": "Request Airway Bill Anteraja",
  "preparingOrderDescription": "Pembeli menunggu kiriman pesanan.",
  "deliveringDescription" : "Menunggu konfirmasi Pembeli terima produk.",
  "delivered": "Terkirim",
  "deliveredDescription" : "Kurir mengkonfirmasi pengiriman sudah selesai.",
  "complain": "Komplain",
  "complainDescription" : "Anda dapat menghubungi Pembeli, menerima atau menolak pengajuan komplain pesanan.",
  "reject" : "Tolak",
  "accept": "Terima",
  "return": "Return",
  "anterAjaRequestPickup" : "Saya akan gunakan jasa Pick-up (jemput)",
  "anterAjaRequestPickupDesc": "Anteraja akan mengambil paket dari alamat Anda",
  'requestPickup' : 'Saya akan gunakan jasa Pick-up (jemput)',
  'requestPickupDesc': 'Kurir akan mengambil paket dari alamat Anda',
  'sendPackage' : 'Saya akan antar ke counter',
  'sendPackageDesc' : 'Anda dapat mengirim paket Anda di cabang {{shipping}} terdekat di kota Anda',
  "notes": "Catatan",
  "dataNotFound": "Data Tidak Ditemukan",
  'cancel_admin' : 'Dibatalkan Admin',
  'awaiting_pickup' : 'Menunggu pickup',
  'awaiting_pickupDescription': 'Menunggu kurir mengambil pesanan.',
  'quantity': 'Jumlah',
  'dateRangeBannerPlaceholder': 'Input Start Date - End Date',
  'dateRangeOrderPlaceholder' : 'Input Start Date - End Date',
  'searchBannerTitlePlaceholder': 'Cari judul banner . . .',
  'image' : 'Gambar',
  'title': 'Judul',
  'url' : 'Url',
  'status' : 'Status',
  'orderBanner': 'Urutan',
  'banner': 'Banner',
  'addNewBanner': 'Tambah Banner Baru',
  'addBanner': 'Tambah Banner',
  'titleBannerPlaceholder' : 'Masukan judul banner',
  'urlBannerPlaceholder' : 'Masukan url banner',
  'orderBannerPlaceholder': 'Masukan order banner',
  'orderDatePlaceholder': 'Masukan tanggal banner',
  'uploadImage': 'Unggah Gambar',
  'save': 'Simpan',
  'draft' : 'Diarsipkan',
  'published' : 'Ditampilkan',
  'savePublish' : 'Simpan & Tampilkan',
  'saveDraft': 'Simpan & Arsipkan',
  'confirmAnterajaRequestPickupMessage': 'Apakah anda yakin ingin Request Pickup ?',
  'bestSizeBanner' : 'Rekomendasi rasio 16:9',
  'trackQty': 'Unlimited',
  'changePassword' : 'Ubah Kata Sandi',
  'currentPassword' : 'Kata Sandi Saat Ini',
  'newPassword' : 'Kata Sandi Baru',
  'confirmNewPassword': 'Konfirmasi Kata Sandi Baru',
  'confirmNewPasswordPlaceholder' : 'Masukan Konfirmasi Kata Sandi Baru',
  'currentPasswordPlaceholder' : 'Masukan Kata Sandi Saat Ini',
  'newPasswordPlaceholder': 'Masukan Kata Sandi Baru',
  'passwordNotSame': 'Konfirmasi kata sandi tidak benar',
  'orderTime': 'Waktu Dipesan',
  'myReport' : 'Laporan Saya',
  'myReportSubtitle': 'Buat, Download & lihat laporan',
  "reportType" : "Jenis Laporan",
  "requestTime" : "Waktu Laporan Dibuat",
  "requestAccount": "Akun",
  "requestName": "Nama Laporan",
  "reportDateRangePlaceholder" : "Input Date Range",
  "basicInformationVoucher" : "Rincian Dasar",
  "voucherTitle": "Nama Voucher",
  "voucherTitlePlaceholder": "Masukan Nama Voucher",
  "voucherCode" : "Kode Voucher",
  "voucherCodePlaceholder": "Masukan Kode Voucher",
  "voucherStartDate" : "Tanggal Voucher Dimulai",
  "voucherStartDatePlaceholder": "Pilih Tanggal",
  "voucherEndDate": "Tanggal Voucher Berakhir",
  "voucherEndDatePlaceholder": "Pilih Tanggal",
  "rewardSettings": "Pengaturan Bonus",
  "voucherMinOrder": "Minimum Pembelian",
  "voucherMinOrderPlaceholder": "Masukan Minimum Pembelian",
  "voucherDiscountType": "Tipe Diskon",
  "voucherDiscountValue": "Nilai Diskon",
  "voucherDiscountValuePlaceholder": "Masukan Nilai Diskon",
  "voucherMaxDiscount": "Maximum Diskon",
  "voucherMaxDiscountPlaceholder": "Masukan Maximum Diskon",
  "voucherLimitType": "Tipe Limit Voucher",
  "voucherLimitValue": "Jumlah Voucher",
  "voucherLimitValuePlaceholder": "Masukan Jumlah Voucher",
  "bankLimitType" : "Tipe Limit Promo",
  "bankLimitValue" : "Nilai Limit Promo",
  "bankLimitValuePlaceholder": "Masukan Nilai Limit Promo",
  "applicableVoucher": "Pengaturan Voucher",
  "voucherApplicableTo": "Voucher Berlaku Untuk",
  "bankApplicableTo": "Promo Berlaku Untuk",
  "applicableCombination": "Bisa Dikombinasikan Dengan",
  "mediaSettings": "Pengaturan Media",
  'bestSizeVoucherImage': 'Rekomendasi rasio gambar 3:1(600px x 200px)',
  "voucherClaimable": "Muncul di Voucher List",
  "addProduct": "Tambah Produk",
  "selectProduct": "Pilih Produk",
  "choosenProduct": "Produk Dipilih",
  "applicableProducts": "Produk yang Berlaku",
  "applicableBrand": "Brand yang Berlaku",
  "addApplicableBrand": "Tambah Brand",
  "addApplicableMerchant": "Tambah Merchant",
  "storeName": "Nama Merchant",
  "selectBrand" : "Pilih Brand",
  "selectMerchant" : "Pilih Merchant",
  "applicableMerchant": "Merchant yang Berlaku",
  "customerSettings": "Pengaturan Pelanggan",
  "applicableCustomer": "Pelanggan",
  "addCustomer": "Tambah Akun Pelanggan",
  "selectCustomer": "Pilih Pelanggan",
  "choosenCustomer" : "Pelanggan Dipilih",
  "choosenMerchant": "Merchant Dipilih",
  "choosenBrand": "Brand Dipilih",
  "back": "Kembali",
  "manageCategory": "Kelola Kategori",
  "editCategory": "Ubah Kategori",
  "categoryName": "Nama Kategori",
  "parentCategory": "Kategori Parent",
  "categoryParentIdPlaceholder": "Ketik untuk mencari kategori",
  "addChildrenCategory": "Tambahkan Children Kategori",
  "delete": "Hapus",
  "addChildrenCategoryInside": "Tambahkan Children Kategori Di dalam",
  "confirmDeleteCategoryMessage": "Apakah anda yakin ingin menghapus kategori ini ?",
  "confirmAddCategoryMessage": "Apakah anda yakin ingin menambah kategori ?",
  "confirmEditCategoryMessage": "Apakah anda yakin ingin mengubah kategori ini ?",
  "addNew": "Tambah",
  "submit": "Submit",
  "voucherName": "Nama Voucher",
  "bankPromoName": "Nama Promo",
  "voucherType": "Tipe Voucher",
  "bankPromoType": "Tipe Promo",
  "voucherQuantity": "Kuantitas",
  "usageLimit": "Batas Penggunaan",
  "claimed": "Diklaim",
  "usage": "Digunakan",
  "period": "Periode Voucher",
  "bankPromoPeriod": "Periode Promo",
  "addNewVoucher": "Tambah Voucher Baru",
  "voucher": "Voucher",
  "addNewVoucherReferral": "Tambah Voucher Referral Baru",
  "voucherReferral": "Voucher Referral",
  "toDoList": "Yang Perlu Dilakukan",
  "businessInsights": "Bisnis Saya",
  "visitors": "Total Pengunjung",
  "pageViews": "Produk Dilihat",
  "conversionRate": "Tingkat Konversi",
  "yesterday": "Kemarin",
  "massUpload" : "Unggah Masal ",
  "downloadTemplate" : "Unduh Template ",
  "uploadFile" : "Unggah Berkas",
  "productSoldOut": "Produk Habis",
  "sales": "Penjualan",
  'messageConfirmDeleteProduct' : 'Apakah anda yakin ingin menghapus Produk ini ?',
  'messageConfirmDeleteVariantProduct' : 'Apakah anda yakin ingin menghapus Variant dari Produk ini ?',
  "roleDetail": "Detil Role",
  "rolesName" : "Name Role",
  "rolesNamePlaceholder": "Masukan Name Role",
  "moduleAccess": "Akses Modul",
  "userLists": "Daftar User",
  "addUser": "Tambah User",
  "userName": "Nama User",
  "userEmail": "Email User",
  "selectUser": "Pilih User",
  "searchUserPlaceholder": "Cari User . . .",
  "module": "Module",
  "access": "Akses",
  "users": "User",
  "addNewRole": "Tambah Role Baru",
  "addModule": "Tambah Module",
  "confirmEditRolesMessage" : "Apakah anda yakin ingin merubah role ini ?",
  "confirmDeleteRolesMessage": "Apakah anda yakin ingin menghapus role ini ?",
  "confirmDeleteBannerMessage" : "Apakah anda yakin ingin menghapus banner ini ?",
  "numberOfEnthusiasts" : "Jumlah Peminat",
  "enthusiast" : "Pelanggan Berminat",
  "gender" : "Jenis Kelamin",
  "addNewUser": "Tambah User Baru",
  "editUser": "Ubah User",
  "createNewUser" : "Buat User Baru",
  "fullName" : "Nama Lengkap",
  "fullNamePlaceholder" : "Masukan Nama Lengkap",
  "email" : "Email",
  "emailPlaceholder" : "Masukan Email",
  "password" : "Kata Sandi",
  "passwordPlaceholder" : "Masukan Kata Sandi",
  "confirmPasswordUser" : "Konfirmasi Kata Sandi",
  "confirmPasswordUserPlaceholder": "Masukan Konfirmasi Kata Sandi",
  "userDetail" : "User Detail",
  "userRole": "User Role",
  "archetype" : "Archetype",
  "searchArchetypePlaceholder" : "Cari Judul . . .",
  "archetypeCover" : "Archetype Cover",
  "addNewArchetype": "Tambah Archetype",
  "archetypeTitle": "Judul Archetype",
  "archetypeTitlePlaceholder": "Masukan Judul Archetype",
  "addArchetype" : "Tambah Archetype",
  "editArchetype" : "Ubah Archetype",
  "archetypeDescription": "Deskripsi Archetype",
  "archetypeDescriptionPlaceholder" : "Masukan Deskripsi Archetype",
  "archetypeTags" : "Tag Archetype",
  "productTag" : "Tag Produk",
  "archetypeImages" : "Gambah Archetype",
  "archetypeStatus": "Status Archetype",
  "archetypeCoverBestSize": 'Rekomendasi Ukuran 236x354 Pixel',
  "typeInput": "Mohon ketik untuk mencari tag",
  "typeInputBrand" : "Mohon ketik untuk mencari brand",
  "createBrand" : "Buat Brand",
  "birthday": "Birthday",
  "MALE" : "Laki-Laki",
  "FEMALE": "Wanita",
  "searchCustomerPlaceholder": "Cari Customer . . .",
  "customerProfile": "Informasi Profil Customer ",
  "customerAccount": "Informasi Akun Customer",
  "customerAddress": "Alamat Customer",
  "addressType": "Tipe Alamat",
  "recipentName": "Nama Penerima",
  "addressDetail": "Detail Alamat",
  "phoneNumber": "Nomor Telepon",
  "customerBank": "Bank Customer",
  "bankName": "Nama Bank",
  "accountNumber": "Nomor Akun",
  "accountName": "Nama Akun",
  "paymentManagement" : "Pengaturan Pembayaran",
  "confirmPaymentStatusInactive" : "Apakah anda yakin Menonaktifkan channel pembayaran ini ?",
  "confirmPaymentStatusActive": "Apakah anda yakin Mengaktifkan channel pembayaran ini ?",
  "shippingManagement" : "Pengaturan Pengiriman",
  "confirmShippingStatusInactive" : "Apakah anda yakin Menonaktifkan jasa pengiriman ini ?",
  "confirmShippingStatusActive": "Apakah anda yakin Mengaktifkan jasa pengiriman ini ?",
  "warningShippingStatus": "Jasa pengiriman tidak boleh kosong!",
  "birthDate" : "Tanggal Lahir",
  "flashSale" : "Penawaran Terbatas",
  "flashSaleName": "Nama Flash Sale",
  "flashSaleNamePlaceholder": "Masukan Nama",
  "flashSaleShortDesc": "Deskripsi Singkat",
  "flashSaleShortDescPlaceholder": "Masukan Deskripsi Singkat",
  "flashSaleStartDate" : "Tanggal Mulai Flash Sale",
  "flashSaleStartDatePlaceholder": "Pilih Tanggal",
  "flashSaleEndtDate" : "Tanggal Berakhir Flash Sale",
  "flashSaleEndtDatePlaceholder": "Pilih Tanggal",
  "createFlashSale" : "Tambah Penawaran Terbatas Baru",
  "productManagement" : "Management Produk",
  "bestSizeFlashSaleImage" : "Rekomendasi rasio gambar 16:9",
  "tnc" : "Syarat dan Ketentuan",
  "tncPlaceholder" : "Masukan Syarat dan Ketentuan",
  "shortDescription" : "Deskripsi Singkat",
  "startDate" : "Tanggal Mulai",
  "endDate" : "Tanggal Berakhir",
  "flashSaleEndDatePlaceholder": "Pilih tanggal",
  "descripiton" : "Deskripsi",
  "descriptionPlaceholder": "Masukan Deskripsi",
  "voucherQuantityPerUser" : "Quantity Per User",
  "voucherQuantityPerUserPlaceholder": "Masukan Quantity Per User",
  "nonSaleItem": "Sale Item",
  "searchStoreLocatorPlaceholder" : "Cari Alamat or Nama Toko",
  "storeLocator" : "Lokasi Toko",
  "addStoreLocator" : "Tambah Lokasi Toko",
  "storeLocatorPhoto" : "Foto Toko",
  "storeLocatorName" : "Nama Toko",
  "storeLocatorAddress" : "Alamat Toko",
  "createStoreLocation" : "Buat Lokasi Toko",
  "storeLocatorNamePlaceholder" : "Masukan Nama Toko",
  "storeLocatorAddressPlaceholder" : "Masukan Alamat Toko",
  "searchAddressStoreLocator" : "Ketik Untuk Mencari Lokasi",
  "storeLocatorPhoneNumber" : "Nomer Telepon Toko",
  "storeLocatorPhoneNumberPlaceholder" : "Masukan Nomer Telepon Toko",
  "storeLocatorPhotoBestSize": 'Rekomendasi rasio foto 4:3(512px x 384px)',
  "locationStore" : "Lokasi Toko",
  "flashSaleDescripiton" : "Deskripsi",
  "flashSaleDescripitonPlaceholder" : "Masukan Deskripsi",
  "flashSalePosition" : "Penempatan",
  "flashSalePositionPlaceholder" : "Pilih Posisi",
  "flashSaleStatusPlaceholder" : "Pilih Status",
  "flashSalePeriod" : "Periode",
  "top" : "Atas",
  "middle" : "Tengah",
  "bottom": "Bawah",
  "brandSearchPlaceholder" : "Cari Brand . . .",
  "brand" : "Brand",
  "brandCover" : "Gambar Brand",
  "brandCode" : "Kode Brand",
  "brandCodePlaceholder" : "Masukan Kode Brand",
  "brandName" : "Nama Brand",
  "brandNamePlaceholder" : "Masukan Nama Brand",
  "brandFeatured": "Featured",
  "brandNotFeatured" : "Not Featured",
  "brandShortDescription" : "Deskripsi Pendek Brand",
  "brandShortDescriptionPlaceholder" : "Masukan Deskripsi Pendek Brand",
  "brandDescription" : "Deskripsi Brand",
  "brandDescriptionPlaceholder" : "Masukan Deskripsi Brand",
  "messageConfirmDeleteBrand": "Apakah anda yakin ingin menghapus Brand ini ?",
  "bestSizeBrandCover" : "Rekomendasi rasio gambar 1:1(400px x 400px)",
  "addBrand" : "Tambah Brand Baru",
  "deals" : "Deals",
  "createDeals" : "Buat Deals Baru",
  "dealNamePlaceholder" : "Masukan Nama",
  "dealsPosition" : "Posisi Deals",
  "dealsPositionPlaceholder" : "Pilih Posisi Deals",
  "dealShortDescriptionPlaceholder" : "Masukan Deskripsi Singkat",
  "dealsDescription" : "Deskripsi",
  "dealsDescriptionPlaceholder" : "Masukan Deskripsi",
  "dealsStartDatePlaceholder" : "Pilih Tanggal Mulai",
  "dealsEndDatePlaceholder" : "Pilih Tanggal Berakhir",
  "dealStatusPlaceholder": "Pilih Status",
  'bestSizeDealsImage' : 'Rekomendasi Rasio Gambar ratio 3:1',
  "productGroupSearchPlaceholder" : "Cari Nama Grup Kategori . . .",
  "productGroup" : "Grup Kategori",
  "addProductGroup" : "Tambah Grup Kategori",
  "productGroupCover" : "Grup Kategori Cover",
  "productGroupName" : "Nama Grup Kategori",
  "productGroupNamePlaceholder" : "Masukan Nama Grup Kategori",
  "productGroupShortDescription" : "Deskripsi Pendek",
  "productGroupShortDescriptionPlaceholder" : "Masukan Deskripsi Pendek",
  "productGroupDescription" : "Deskripsi",
  "productGroupDescriptionPlaceholder" : "Masukan Deskripsi",
  "productGroupOnNavigation" : "Taruh Di Navigasi",
  "productGroupStatus": "Status",
  "bestSizeProductGroupCover": "Rekomendasi ukuran 1276px x 316px",
  "messageConfirmDeleteProductGroup": "Apakah anda yakin ingin menghapus Grup Kategori ini ?",
  "productGroupOrder": "Urutan Grup Kategori",
  "productGroupOrderPlaceholder": "Masukan Urutan Grup Kategori",
  "searchNewsPlaceholder" : "Search News . . .",
  "news" : "Berita",
  "addNews" : "Tambah Berita",
  "newsCover" : "Gambar Berita",
  "newsTitle" : "Judul Berita",
  "newsTitlePlaceholder" : "Masukan Judul Berita",
  "newsCategory" : "Kategori",
  "newsCategoryPlaceholder" : "Pilih Kategori",
  "newsShortDescription" : "Deskripsi Pendek",
  "newsShortDescriptionPlaceholder" : "Masukan Deskripsi Pendek",
  "newsTag" : "Tags",
  "editNews": "Ubah Berita",
  "newsContent" : "Konten Berita",
  "newsContentPlaceholder" : "Masukan Konten Berita",
  "newsSlug" : "Slug Berita",
  "newsSlugPlaceholder" : "Masukan Slug Berita",
  "newsStatus" : "Status Berita",
  "newsCoverBestSize" : "Rekomendasi Rasio Gambar ratio 16:9",
  "discussion" : "Diskusi",
  "reportedDiscussion" : "Terlaporkan",
  "suspendedDiscussion" : "Telah di Suspen",
  "discussionUser" : "Pengguna",
  "discussionProduct" : "Produk",
  "discussionMessage" : "Diskusi",
  "discussionReply" : "Balasan Terakhir",
  "discussionDate" : "Tanggal",
  "productDiscussionReply" : "Balasan",
  "blockDiscussion" : "Blokir",
  "unblockDiscussion" : "Unblock",
  "replyDiscussion" : "Balas",
  "replyDiscussionPlaceholder" :  "Tulis balasan",
  "discussionReported" : "Dilaporkan",
  "discussionBlocked" : "Diblokir",
  "viewAllDiscussion" : "Lihat Semua Diskusi",
  "discussionBlockConfirmationMessage" : "Apakah anda yakin ingin memblokir diskusi ini ?",
  "discussionUnblockConfirmationMessage" : "Apakah anda yakin ingin membuka diskusi ini ?",
  "notifications" : "NOTIFIKASI",
  "createNewNotifications" : "Buat Notifikasi Baru",
  "notificationList" : "Daftar Notifikasi",
  "notificationMessage" : "Pesan Notifikasi",
  "notificationNamePlaceholder" : "Masukan Judul",
  "notificationMessagePlaceholder" : "Masukan Pesan Notifikasi",
  "notificationPublishDate" : "Tanggal Publikasi",
  "notificationRecipient" : "Penerima",
  "notificationRecipientPlaceholder" : "Pilih Penerima",
  "notificationTitle" : "Judul Campaign",
  "notificationStatus" : "Status",
  "notificationStatusPlaceholder" : "Masukan Status",
  "blockingReasonPlaceholder" : "Masukkan alasan anda untuk memblokir",
  "blockingReason" : "Alasan",
  "storeUser" : "Toko",
  "storeUserPlaceholder" : "Pilih Toko",
  "editProfile" : "Ubah Profil",
  "changePhoto" : "Ubah Foto",
  "editEmail" : "Email",
  "editName": "Nama",
  "editStore" : "Toko",
  "addCategoryNews" : "Tambah Kategori Berita",
  "editCategoryNews" : "Ubah Kategori Berita",
  "categoryStatus" : "Status Kategori",
  "categoryNewsName" : "Nama Kategori",
  "categoryNewsNamePlaceholder": "Masukan Kategori Berita",
  "addNewsCategory": "Tambah Kategori Berita",
  "newsTags" : "Tags",
  "unread": "Belum Dibaca",
  "seeMore": "Lihat Selengkapnya",
  "markAsRead" : "Tandai sebagai membaca",
  "bestSizeNotificationImage": "Rekomendasi rasio 4:3",
  "numberOfReview" : "Jumlah Ulasan",
  "productRating" : "Penilaian Produk",
  "productReviews": "Ulasan Produk",
  "emptyNotification": "Notifikasi Kosong",
  "createMerchant": "Buat Toko Penjual",
  "merchantStoreUrl" : "Url Toko Penjual",
  "merchantStoreName" : "Nama Toko Penjual",
  "merchantStoreNamePlaceholder" : "Masukan Nama Toko Penjual",
  "merchantUserFullname" : "Nama Admin",
  "merchantUserFullnamePlaceholder" : "Masukan Nama Admin",
  "merchantUserEmail" : "Email Admin",
  "merchantUserEmailPlaceholder" : "Masukan Email Admin",
  "merchantUserPassword" : "Kata Sandi Admin",
  "merchantUserPasswordPlaceholder" : "Masukan Kata Sandi Admin",
  "merchantUserConfirmPassword" : "Konfirmasi Kata Sandi Admin",
  "merchantUserConfirmPasswordPlaceholder": "Masukan Konfirmasi Kata Sandi Admin",
  "store": "Toko",
  "merchant" : "Toko Penjual",
  "merchantSearchPlaceholder" : "Cari Penjual . . .",
  "merchantName" : "Nama",
  "merchantCity" : "Kota",
  "merchantJoinDate" : "Tgl Bergabung",
  "merchantTotalProduct" : "Total Produk",
  "merchantStatus": "Status",
  "merchantDetail" : "Detil Penjual",
  "merchantLogo" : "Logo Penjual",
  "merchantShortDiscription" : "Tagline Penjual",
  "merchantDiscription" : "Dekripsi Penjual",
  "merchantImageCover" : "Gambar Cover Penjual",
  "merchantOperationalHours" : "Jam Operasi Penjual",
  "merchantProduct": "Produk Penjual",
  "confirmMerchantChangeStatusMessage": "Apakah anda yakin ingin mengubah status penjual ini ?",
  "addMerchant": "Tambah Toko Penjual",
  "addAddress" : "Tambah Alamat",
  "addressStoreName" : "Label",
  "addressStoreDetail" : "Alamat",
  "addressStorePhoneNumber": "Nomor Telepon",
  "createAddress" : "Tambah Alamat",
  "addressNamePlaceholder" : "Masukan Label Alamat Contoh(Home, Office, Warehouse)",
  "addressDetailPlaceholder" : "Masukan Detail Alamat",
  "locationAddress" : "Lokasi Alamat",
  "addressStorePhoneNumberPlaceholder": "Masukan Nomor Telepon",
  "addressLabel" : "Label Alamat",
  "addressDefault": "Alamat Utama",
  "monday" : "Senin",
  "tuesday" : "Selasa",
  "wednesday" : "Rabu",
  "thursday" : "Kamis",
  "friday" : "Jumat",
  "saturday" : "Sabtu",
  "sunday" : "Minggu",
  "storeProfileTitle" : "Profile Title",
  "profileTitlePlaceholder" : "Entre Profile Title",
  "profileTitleDescription" : "Deskripsi Toko",
  "profileTitleDescriptionPlaceholder" : "Masukan Deskripsi Toko",
  "profileLogo" : "Logo",
  "bestSizeStoreLogo" : "",
  "bestSizeStoreImageCover": "Rekomendasi Rasio 16:9",
  "profileName" : "Nama Toko",
  "profileNamePlaceholder" : "Masukan Nama Toko",
  "profileShortDescription" : "Tagline Toko",
  "profileShortDescriptionPlaceholder": "Masukan Deskripsi Pendek Toko",
  "storeImageCover": "Gambar Cover Toko",
  "storeOperationalHours" : "Jam Operasional Toko",
  "storeProfile": "Profil Toko",
  "productGroupTotalProduct": "Total Produk",
  "targetLink": "Target Link",
  "productDetail" : "Detil Produk",
  "groupCategory" : "Grup Kategori",
  "otherUrl": "Url Lain",
  "selectProductGroup": "Pilih Grup Kategori",
  "addressPostalCode": "Provinsi/Kota/Distrik",
  "typeInputPostalCode": "Mohon ketik untuk mencari Provinsi/Kota/Distrik",
  "editAddress": "Ubah Alamat",
  "moderatedProduct" : "Moderasi Produk",
  "moderatedReason" : "Alasan",
  "moderatedReasonPlaceholder" : "Masukan Alasan",
  "availableForBrightspot" : "Hanya di Brightspot",
  "voucherPlatform": "Voucher",
  "storeUrl": "Url Toko",
  "addressPostalCodeNumber": "Kode Pos",
  "isMinLength": "Minimum {{value}} karakter",
  "confirmDeleteEmailMessage": "Apakah anda yakin ingin menghapus email ini ?",
  "addEmail": "Tambah Email",
  "emailInputPlaceholder" : "Masukan Email",
  "searchEmailPlaceholder": "Cari Email . . .",
  "isTaken" : "sudah terdaftar",
  "inputAirwayBill": "Input Resi",
  "awbInput" : "Nomor Resi",
  "awbInputPlaceholder": "Masukan Nomor Resi",
  "checkAwb": "Cek Resi",
  "here": "disini",
  "cancel_by_system": "Dibatalkan Sistem",
  "cancel_by_systemDescription": "Pesanan dibatalkan oleh sistem, batas waktu proses telah habis.",
  "completedDescription": "Pembeli telah menerima pesanan.",
  "pleaseSelectCategory": "Silahkan Pilih Kategori",
  "errorFieldButton": "Lengkapi semua field diatas yang wajib diisi",
  "errorFieldButtonNoVariant": "Lengkapi setidaknya 1 variasi",
  "pleaseChooseDate": "Silahkan Pilih Tanggal Pickup",
  "chooseTimeAirwaybill": "Silahkan Pilih Waktu Pickup",
  "changeAwb": "Ubah Resi",
  "nonSaleItemVoucher": "Berlaku juga untuk sale item",
  "typeInputStoreList": "Mohon ketik untuk mencari Toko",
  "userType": "Tipe User",
  "historyModeration" : "Riwayat Moderasi",
  "inReviewProduct" : "Product Di Review",
  "moderated": "Moderasi",
  "yourProductModereted": "Product Anda Sedang di Moderasi",
  "yourProductinReview" : "Product ini sedang di Review",
  "reason": "Alasan",
  "see": "Lihat",
  "in_review": "Sedang Di Review",
  "welcomeMessage" : "Pengaturan Pesan Selamat Datang",
  "welcomeMessagePlaceholder": "Masukan Pesan Selamat Datang",
  "bankPromo": "Promo Bank",
  "addNewBankPromo" : "Tambah Promo Bank",
  "bankPromoTitle" : "Nama Promo Bank",
  "bankPromoTitlePlaceholder" : "Masukan Nama Promo Bank",
  "bankPromoStartDate" : "Tanggal Promo Bank Dimulai",
  "bankPromoStartDatePlaceholder" : "Masukan Tangga Promo Bank Dimulai",
  "bankPromoEndDate" : "Tanggal Promo Bank Berakhir",
  "bankPromoEndDatePlaceholder" : "Masukan Tanggal Promo Bank Berakhir",
  "voucherTnc": "Syarat & Ketentuan",
  "voucherTncPlaceholder": "Masukan Syarat & Ketentuan",
  "voucherPromoBankCanCombineWithVoucher" : "Bisa Dikombinasikan Dengan Voucher",
  "bankSettings" : "Pengaturan Bank",
  "voucherPromoBankPayment" : "Payment Method",
  "typeInputVoucherPromoBankPayment" : "Select Payment Method",
  "voucherPromoBankBin" : "Nomor BIN",
  "voucherPromoBankBinPlaceholder" : "Masukan Nomor BIN",
  "voucherPromoBankInstallment" : "Cicilan Pembayaran",
  "voucherPromoBankInstallmentPlaceholder": "Pilih Cicilan Pembayaran",
  "applicablePromo": "Pengaturan Promo",
  "userCustomerService": "Customer Service",
  "voucherShortDescription" : "Deskripsi Pendek",
  "voucherShortDescriptionPlaceholder": "Masukan Deskripsi Pendek",
  "voucherAlreadyExist": "Sudah Dipakai",
  "programName" : "Nama Program",
  "programNamePlaceholder" : "Masukan Nama Program",
  "programShortDescription" : "Deskripsi Pendek Program",
  "programShortDescriptionPlaceholder" : "Masukan Deskripsi Pendek Program",
  "programStartDate" : "Tanggal Program Dimulai",
  "programEndDate" : "Tanggal Program Berakhir",
  "programParticipant" : "Peserta Program",
  "programConversionTriggerPlaceholder" : "Pilih Conversion Trigger",
  "programConversionTrigger": "Conversion Trigger",
  "next" : "Selanjutnya",
  "prev": "Sebelumnya",
  "programDetail": "Program Detail",
  "referralProgram" : "Referral Program",
  "openedProgram" : "Opened",
  "openedConverted": "Converted",
  "addNewReferral": "Tambah Program Referral Baru",
  "referralActiveProgramBlock" : "Program referral lain sedang berjalan, anda tidak bisa menjalankan beberapa program referral secara bersamaan.",
  "errorSyncHaistar": "SKU Tidak Ditemukan",
  "massUpdateDownloadTime": "Tanggal",
  "massUpdateDownloadName": "Nama File",
  "massUpdate": "Mass Update",
  "generateTemplate" : "Generate Template",
  "dndExcelHere": "Pilih atau letakan file excel di sini",
  "browseFile" : "Pilih File",
  "massUpdateDownloadSubtitle" : "Pilih dan download template di bawah ini untuk mengupdate produk kamu dengan Microsoft Excel.",
  "massUpdateUploadSubtitle" : "Mohon upload file yang sudah lengkap untuk menyelesaikan proses Mass Update.",
  "massUpdateDownloadSubtitle30Days" : "The Goods Dept hanya menyimpan file kamu dalam 30 hari terakhir.",
  "massUpdateDownloadTips1" : "Fitur Mass Update The Goods Dept hanya mendukung:",
  "massUpdateDownloadTips2" : "Microsoft Excel 2007 & versi PC Windows yang lebih tinggi.",
  "massUpdateDownloadTips3": "Ukuran file TSV harus kurang dari 5MB.",
  "massUpdateUploadTips1" : "Ukuran maks. 5MB. Gunakan format TSV.",
  "massUpdateUploadTips2" : "Kamu dapat mengedit produk / informasi sku dan memperoleh filenya dari pilihan Ubah sebagian Informasi Produk.",
  "massUpdateUploadTips3": "Mohon tidak mengedit produk sebelum proses selesai..",
  'downloaded': 'telah di download',
  "massUpdateDownloadProcessed" : "Telah diproses",
  "tryAgain": "Coba lagi",
  "done" : "Selesai",
  "generalVoucher" : "Voucher General",
  "voucherStore" : "Voucher Toko",
  "addNewVoucherStore": "Tambah Voucher Toko",
  "addNewVoucherPlatform" : "Add Voucher",
  "paymentCancel": "Dibatalkan",
  "voucherAvaliablePlatform":"Platform ",
  "bulkPrintShipping": "Cetak Resi",
  "createLabel" : "Cetak",
  "createLabelThermal" : "Cetak Thermal",
  "choosenOrder" : "Pesanan Terpilih",
  "printDocument": "Cetak Dokumen",
  "ANTERAJA_REGULAR": "ANTERAJA",
  "ANTERAJA_SAMEDAY" : "ANTERAJA",
  "messageConfirmProductChangeStatusTo": "Apakah anda yakin ingin mengubah status produk ke",
  "outOfStock": "Stok Habis",
  "erorImage" : "Terjadi Kesalahan, foto yang kamu masukkan melebihi slot foto yang tersedia.",
  "deniedChangeStatus": "Tidak dapat mengubah status, data produk belum lengkap.",
  "promoDiscountOngoing" : "Sedang Berjalan",
  "promoDiscountNext" : "Akan Datang",
  "promoDiscountEnded" : "Telah Berakhir",
  "namePromoDiscount" : "Nama Promosi",
  "promoDiscountProduct" : "Produk",
  "promoDiscountPeriod": "Periode",
  "promoDiscount" : "Diskon Toko",
  "ongoingPromoDiscount" : "Sedang Berjalan",
  "nextPromoDiscount" : "Akan Datang",
  "expiredPromoDiscount": "Telah Berakhir",
  "addPromoDiscount": "Tambah Diskon Toko",
  "massChangePromoDiscount": "Perubahan Massal",
  "priceSale" : "Harga Sale",
  "stockPromotion" : "Stok Promosi",
  "maxPurchase" : "Batas Pembelian",
  "activeInactive": "Aktifkan / Nonaktifkan",
  "createPromoDiscount" : "Tambah Diskon Toko",
  "basicInformationPromoDiscount" : "Informasi Dasar",
  "discountName" : "Nama Diskon",
  "discountNamePlaceholder" : "Masukan Nama Diskon",
  "discountStartDate" : "Tanggal Diskon Dimulai",
  "discountStartDatePlaceholder" : "Masukan Tanggal Diskon Dimulai",
  "discountEndDate" : "Tanggal Diskon Berakhir",
  "discountEndDatePlaceholder" : "Masukan Tanggal Diskon Berakhir",
  "productPromoDiscount" : "Produk dalam Diskon Toko",
  "productPromoDiscountDesc" : "Tambahkan produk ke dalam Diskon Toko dan atur harga diskon",
  "totalProduct" : "Total Produk",
  "stockDiscountPromo" : "Stok Promosi",
  "noUpdate" : "Tidak ada update",
  "unlimited" : "Tidak Terbatas",
  "limited" : "Terbatas",
  "changeAll" : "Ubah Semua",
  "invalidPrice": "Harga Tidak Sah",
  "min99": "Jumlah min. 99",
  "min1" : "Min. value 1",
  "maxStockPromo": "Stok promosi harus kurang dari stok yang tersedia saat ini.",
  "warningPricePromoDiscount": "Harga promo kurang dari 40% harga awal",
  "variantIsOnDiscount": "Produk ini sedang dalam masa promosi",
  "inactivePromoDiscount" : "Tidak Aktif",
  "promoDiscountInactive": "Tidak Aktif",
  'messageConfirmPromoDiscountEnd': 'Apakah Anda yakin ingin mengakhiri Diskon Toko ini ?',
  'endPromoDiscount' : 'Akhiri',
  "membershipName" : "Nama Membership",
  "membershipNamePlaceholder" : "Name Enter Membership",
  "membershipDescription" : "Deskripsi Membership",
  "membershipDescriptionPlaceholder" : "Masukan Deskripsi Membership",
  "membershipImage" : "Gambar Membership",
  "membershipSkuPlaceholder": "Masukan SKU Membership",
  "setupMembership" : "Atur Membership",
  "setupMembershipDesc": "Atur membership untuk pelanggan anda",
  "statusMembership": "Status",
  "enter" : "Masukan",
  "paymentStatus" : "Status Pembayaran",
  "paymentStatusDesc": "Cek status pembayaran membership",
  'pinpointAddress': 'Pin Alamat',
  'searchPinpointAddress': 'Cari Alamat . . .',
  'confirmIntantBiteshipRequestPickupMessage': 'Apakah anda yakin ingin Request Pickup sekarang?',
  'productLength': 'Panjang',
  'productLengthPlaceholder': 'Masukan panjang produk anda',
  'productWidth': 'Lebar',
  'productWidthPlaceholder': 'Masukan lebar produk anda',
  'productHeight': 'Tinggi',
  'productHeightPlaceholder': 'Masukan tinggi produk anda',
  'SELF_PICKUP': '',
  'processRequestPickup': 'Pesanan Siap diambil',
  'processReadyToPickupConfirmMessage': "Apakah pesanan sudah siap diambil ?",
  'pickupCode': "Kode Pengambilan",
  'awaiting_pickupSelfPickup' : 'Menunggu Pengambilan',
  'awaiting_pickupDescriptionSelfPickup': 'Menunggu customer mengambil pesanan.',
  'externalUrl': 'External Url',
  'addTieredDiscount': 'Tambah Diskon Bertingkat',
  'titleTieredDiscount': 'Judul',
  'titleTieredDiscountPlaceholder': 'Masukan judul diskon bertingkat',
  'codeTieredDiscount': 'Kode',
  'codeTieredDiscountPlaceholder': 'Masukan kode diskon bertingkat',
  'shortDescriptionTieredDiscount': 'Deskripsi pendek',
  'shortDiscriptionTieredDiscountPlaceholder': 'Masukan deskripsi pendek diskon bertingkat',
  'startDateTieredDiscount': 'Tanggal Dimulai',
  'startDateTieredDiscountPlaceholder': 'Masukan tanggal dimulai diskon bertingkat',
  'endDateTieredDiscount': 'Tanggal Berakhir',
  'endDateTieredDiscountPlaceholder': 'Masukan tanggal berakhir diskon bertingkat',
  'limitTypeTieredDiscount': 'Tipe Limit',
  'limitValueTieredDiscount': 'Jumlah diskon',
  'limitValueTieredDiscountPlaceholder': 'Masukan Jumlah diskon bertingkat',
  'quantityPerUserTieredDiscount': 'Quantity Per User',
  'quantityPerUserTieredDiscountPlaceholder': 'Masukan quantity per user',
  'termAndConditionTieredDiscount': 'Syarat & Ketentuan',
  'termAndConditionTieredDiscountPlaceholder': 'Masukan syarat & ketentuan',
  'tierCounter': 'Level Diskon Bertingkat',
  'tierDiscountValuePlaceholder': 'Masukan jumlah diskon',
  'tieredDiscountConfig': 'Pengaturan Diskon Bertingkat',
  'tieredDiscountApplicableTo': 'Berlaku Untuk',
  'tieredDiscountCanCombineWithBankPromo': 'Bisa di kombinasikan dengan bank promo',
  'labelNameTieredDiscount': 'Nama Label',
  'labelNameTieredDiscountPlaceholder': 'Masukan nama label diskon bertingkat',
  'tieredDiscountSearchPlaceholder': 'Cari Diskon Bertingkat . . .',
  'tieredDiscount': 'Diskon Bertingkat',
  'tieredDiscountName': 'Nama Diskon Bertingkat',
  'tieredDiscountlevel': 'Level Diskon Bertingkat',
  'tieredDiscountType': 'Tipe ',
  'tieredDiscountPeriod': 'Periode',
  'seeMoreLevel': 'Lihat {{value}} Level lagi',
  'validationErrorApplicableTo': 'Produk ini tidak dapat dimasukkan kedalam program promo karena sudah masuk kedalam program promo lain, mohon hapus untuk melanjutkan',
  'selectAll': 'Select All',
  'promoDiscountSearchPlaceholder': 'Cari Diskon Toko . . .',
  'captionRequestPickupDatetime':'Pilih waktu penjemputan antara pukul 09.00 AM - 02.00 PM',
  "process":"Proses",
  "integrateWithGinee":"Integrasi dengan Ginee",
  "flashSaleSettings":"Pengaturan Penawaran Terbatas",
  "flashSaleTargetType":"Penawaran Terbatas Berlaku Untuk",
  "flashSaleTargetTypePlaceholder" : "Pilih Target Penawaran Terbatas",
  "applicablePromoDiscount":"Diskon Toko yang Berlaku",
  "selectPromoDiscount": "Pilih Diskon Toko",
  "choosenPromoDiscount": "Diskon Toko Dipilih",
  'productPriceOnFlashSale' : 'Harga Penawaran terbatas',
  'productStockOnFlashSale' : 'Stok Penawaran Terbatas',
  "stockSale" : "Stok Sale",
  "warningPriceFlashSale": "Harga penawaran terbatas kurang dari 40% harga awal",
  "invalidStock": "Stok tidak Sah",
}

