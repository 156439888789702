/* eslint-disable @typescript-eslint/no-use-before-define */
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { language } from './i18n.selectors';

import { en } from './source/en';
import { id } from './source/id';

class CustomLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    const resource = lang === 'id' ? id : en;
    return of(resource);
  }
}
@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'id',
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {
  constructor(translate: TranslateService, private store: Store<any>) {
    translate.addLangs(['id', 'en']);
    this.store.pipe(select(language)).subscribe((language) => {
      setTimeout(() => {
        translate.use(language);
      }, 100);
    });
  }
}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
