/* eslint-disable @typescript-eslint/no-this-alias */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment-timezone';
import * as inboxActions from 'src/app/views/inbox/store/inbox.actions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { PluginsService } from './plugins.service';
import * as fromApp from './store/app.reducer';
import * as AuthActions from './views/auth-pages/store/auth.actions';
import * as i18nActions from './i18n/i18n.actions';
import { MessagingService } from './shared/services/messaging.service';
import { TgdSnackbarService } from './shared/tgd-snackbar.service';
import { PushNotification } from './shared/models/firebase.model';
import { ResponseService } from './shared/response.service';
import { isProfileLoaded } from './views/auth-pages/store/auth.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('templateModalUnauthorized') templateModalUnauthorized: TemplateRef<any>;
  isProfileLoaded$: Observable<boolean>;
  taptalkTicket = null;
  constructor(
    private plugins: PluginsService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    public messagingService: MessagingService,
    private tgdSnackbarService: TgdSnackbarService,
    private responseService: ResponseService,
    private modalService: BsModalService
  ) {
    moment.tz.setDefault(moment.tz.guess());
    this.isProfileLoaded$ = this.store.select(isProfileLoaded);
  }

  ngOnInit() {
    this.messagingService.receiveMessage();
    this.messagingService.currentMessage.subscribe((e: PushNotification) => {
      if (e.data && e.data.topicType && e.data.topicType === 'MassUpdateDownloadTemplateSuccess') {
        this.tgdSnackbarService.showToastInfo('Mass Update', 'Template Generated.', 'ri-file-excel-line', '/mass-update');
      } else if (e.data && e.data.topicType && e.data.topicType === 'MassUpdateDownloadTemplateError') {
        this.tgdSnackbarService.showToastInfo('Mass Update', 'Generating Template Error.', 'ri-close-line', '/mass-update', true);
      } else if (e.data && e.data.topicType && e.data.topicType === 'MassUpdateSuccess') {
        this.tgdSnackbarService.showToastInfo('Mass Update', 'Process Succeeded.', 'ri-check-line', '/mass-update#report');
      } else if (e.data && e.data.topicType && e.data.topicType === 'MassUpdateError') {
        this.tgdSnackbarService.showToastInfo('Mass Update', 'Process Mass Error.', 'ri-close-line', '/mass-update#report', true);
      } else if (e.notification.title === 'Payment confirmed') {
        this.tgdSnackbarService.showToastInfo(e.notification.title, e.notification.body, 'ri-shopping-cart-line', '/order');
        this.loadNotification();
      } else {
        this.tgdSnackbarService.showToastInfo(e.notification.title, e.notification.body, 'ri-file-chart-line', '/report');
        this.loadNotification();
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // Init all plugins...
    const current = this;

    setTimeout(() => {
      current.plugins.dashboardIndex();
    }, 200);

    this.store.dispatch(AuthActions.autoLogin());
    this.store.dispatch(i18nActions.autoLanguage());

    this.responseService.apiUnauthorizedSubject.subscribe((e) => {
      if (e) {
        this.openUnauthorizedModal();
      } else {
        this.closeUnauthorizedModal();
      }
    });
  }
  openUnauthorizedModal() {
    this.modalRef = this.modalService.show(this.templateModalUnauthorized, { id: 809, class: 'modal-sm', ignoreBackdropClick: true });
  }
  closeUnauthorizedModal() {
    this.modalRef.hide();
  }
  onCloseUnauthorizedModal() {
    this.responseService.apiUnauthorizedSubject.next(false);
  }
  loadNotification() {
    const params = {
      limit: 10,
      page: 1,
    };
    this.store.dispatch(inboxActions.loadInbox({ params }));
    this.store.dispatch(inboxActions.loadUnreadCount());
  }
}
