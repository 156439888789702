import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Subject } from 'rxjs';
import { v4 as UUIDV4 } from 'uuid';
import { PushNotification } from '../models/firebase.model';
import { ResponseService } from '../response.service';
@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage: Subject<PushNotification> = new Subject();
  public taptalkModule = null;
  public taptalkShowroom = false;
  public taptalkUnreadCounter = 0;
  constructor(private angularFireMessaging: AngularFireMessaging, private http: ResponseService) {}
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.sendDevices(token).subscribe();
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  toggleCloseTaptalk() {
    this.taptalkShowroom = !this.taptalkShowroom;
  }
  sendDevices(pushToken) {
    const localDeviceId = localStorage.getItem('deviceId');
    let deviceId = null;
    if (localDeviceId) {
      deviceId = localDeviceId;
    } else {
      deviceId = UUIDV4();
      localStorage.setItem('deviceId', deviceId);
    }
    const body = {
      deviceId,
      pushToken,
    };
    return this.http.post('/devices', body);
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: PushNotification) => {
      this.currentMessage.next(payload);
    });
  }
}
