import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import * as i18nActions from './i18n.actions';

@Injectable()
export class I18nEffects {
  autoLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(i18nActions.autoLanguage),
      map(() => {
        const language = localStorage.getItem('lang');
        if (language) {
          return i18nActions.setLanguage({ language });
        }
        return { type: 'CANNOT CREATE LANGUAGE' };
      })
    )
  );

  setLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(i18nActions.setLanguage),
        tap((actions) => {
          localStorage.setItem('lang', actions.language);
        })
      ),
    { dispatch: false }
  );
  constructor(private actions$: Actions) {}
}
