import { AccessRole } from '../roles/models/roles.model';

export class User {
  constructor(
    public email: string,
    public id: string,
    public refreshToken: string,
    public accessToken: string,
    public tokenExpirationDate: string,
    public taptalkTicket: string
  ) {}
}

export class LoginRequest {
  constructor(public email: string, public password: string) {}
}

export interface AclType {
  [name: string]: (AccessRole | '*')[];
}
