import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxMaskModule } from 'ngx-mask';

import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TheGoodsModule } from '../the-goods/the-goods.module';
import { OnlyNumber, InputNoSlash, InputNoSpace, HasRoleDirective, DndDirective, InputNoSymbol } from './directives';
import { TableComponent } from './table/table.component';
import { FilterPipe, StripHtmlPipe } from './pipe/tgd.pipe';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { BasicImageFileInputComponent } from './basic-image-file-input/basic-image-file-input.component';

import { BrandModalFormComponent } from './brand-input/brand-modal-form/brand-modal-form.component';

import { CategorySelectorComponent } from '../views/product/components/category-selector/category-selector.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { TableProductComponent } from '../views/voucher/components/table-product/table-product.component';
import { TableBrandComponent } from '../views/voucher/components/table-brand/table-brand.component';
import { TableProductGroupComponent } from '../views/banner/components/table-product-group/table-product-group.component';
import { PostalcodeInputComponent } from './postalcode-input/postalcode-input.component';
import { StoreListInputComponent } from './store-list-input/store-list-input.component';
import { PlaceInputComponent } from './place-input/place-input.component';
import { ChooseProductSelectAllComponent } from './choose-product-select-all/choose-product-select-all.component';
import { ChooseBrandSelectAllComponent } from './choose-brand-select-all/choose-brand-select-all.component';
import { ChoosePromoDiscountComponent } from '../views/flash-sale/components/choose-promo-discount/choose-promo-discount.component';
import { TablePromoDiscountComponent } from '../views/flash-sale/components/table-promo-discount/table-promo-discount.component';

export function getTooltipConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    adaptivePosition: false,
  });
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TheGoodsModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
    NgSelectModule,
    ClickOutsideModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TheGoodsModule,
    ModalModule,
    TooltipModule,
    PaginationModule,
    BsDropdownModule,
    NgxMaskModule,
    OnlyNumber,
    InputNoSlash,
    InputNoSpace,
    InputNoSymbol,
    HasRoleDirective,
    DndDirective,
    TableComponent,
    TranslateModule,
    BasicImageFileInputComponent,
    CategorySelectorComponent,
    UploadImageComponent,
    TagInputComponent,
    StripHtmlPipe,
    ClickOutsideModule,
    BrandModalFormComponent,
    TableProductComponent,
    TableProductGroupComponent,
    PostalcodeInputComponent,
    StoreListInputComponent,
    PlaceInputComponent,
    ChooseProductSelectAllComponent,
    ChooseBrandSelectAllComponent,
    TableBrandComponent,
    ChoosePromoDiscountComponent,
    TablePromoDiscountComponent,
  ],
  providers: [BsModalRef, StripHtmlPipe, { provide: TooltipConfig, useFactory: getTooltipConfig }],
  declarations: [
    OnlyNumber,
    InputNoSlash,
    InputNoSpace,
    InputNoSymbol,
    HasRoleDirective,
    DndDirective,
    TableComponent,
    FilterPipe,
    ConfirmationDialogComponent,
    BasicImageFileInputComponent,
    CategorySelectorComponent,
    TagInputComponent,
    StripHtmlPipe,
    UploadImageComponent,
    BrandModalFormComponent,
    TableProductComponent,
    TableProductGroupComponent,
    PostalcodeInputComponent,
    StoreListInputComponent,
    PlaceInputComponent,
    ChooseProductSelectAllComponent,
    ChooseBrandSelectAllComponent,
    TableBrandComponent,
    ChoosePromoDiscountComponent,
    TablePromoDiscountComponent,
  ],
})
export class SharedModule {}
