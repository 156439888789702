import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { v4 as UUIDV4 } from 'uuid';
import { ResponseService } from '../response.service';
import { detectDevice } from '../helper';

export interface Tracker {
  deviceId: string;
  deviceName: string;
  eventGroup: string;
  eventInformation: string;
  eventFailed: string;
  eventName: string;
  eventResult: string;
  model: string;
}
@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private http: ResponseService, private store: Store<any>) {}
  tracking(
    eventGroup: string,
    eventName: string,
    eventResult: string,
    eventInformation: string = '',
    eventFailed: string = ''
  ): Observable<any> {
    const localDeviceId = localStorage.getItem('deviceId');
    let deviceId = null;
    if (localDeviceId) {
      deviceId = localDeviceId;
    } else {
      deviceId = UUIDV4();
      localStorage.setItem('deviceId', deviceId);
    }

    const body: Tracker = {
      deviceName: detectDevice(),
      eventGroup,
      eventName,
      eventResult,
      eventInformation,
      eventFailed,
      deviceId,
      model: navigator.userAgent,
    };
    return this.http.post('/tracker', body);
  }
}
